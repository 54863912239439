export function updateCartaoCredito( payload ) {
  return {
    type: `UPDATE_CARTAO_CREDITO`,
    payload
  }
}

export const receiveErrosCartaoCredito = payload => {
  return {
    type: `RECEIVE_ERROS_CARTAO_CREDITO`,
    payload
  }
}
