import { Model, attr } from 'redux-orm'

import { empty } from '../../shared/utils/validators'
import TitleClass from '../../shared/utils/titleCaps'

export default class Etiqueta extends Model {
  static get fields() {
    return {
      de: attr(),
      para: attr(),
      erros: attr()
    }
  }

  static parse( dadosEtiqueta ) {
    const { de: deSemTratamento, para: paraSemTratamento, erros=[], ...dados } = dadosEtiqueta
    const de = TitleClass.transform( deSemTratamento )
    const para = TitleClass.transform( paraSemTratamento )
    return this.upsert( { de, para, erros, ...dados } )
  }

  get isEmpty() {
    return empty( this.de ) && empty( this.para )
  }

  validar() {
    const erros = []
    if ( !empty( this.de ) &&  empty( this.para ) ) {
      erros.push( { campo: 'para', mensagem: `"Para:" deve ser preenchido quando "De:" é preenchido` } )
    } else if ( empty( this.de ) &&  !empty( this.para ) ) {
      erros.push( { campo: 'de', mensagem: `"De:" deve ser preenchido quando "Para:" é preenchido` } )
    }
    return erros
  }

  getErro( campo ) {
    const erro = this.erros.find( erro => erro.campo === campo )
    return erro ? erro.mensagem : ``
  }

  toJSON() {
    return { ...this.ref }
  }
}

Etiqueta.modelName = `Etiqueta`
