import { getSessaoEntidades } from '../entidades/selectors'
window.dataLayer = window.dataLayer || [];

export const GTM_TRANSACTION_EVENT = `GTM_TRANSACTION_EVENT`
export const gtmTransactionEvent = () => {
  return function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Pessoa } = sessao

    const assinaturaPresente = AssinaturaPresente.all().first()
    const presenteador = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )

    const transaction = {
      transactionId: presenteador.cpf,
      transactionAffiliation: `TAG Livros`,
      transactionTotal: assinaturaPresente.plano.valorTotal,
      transactionProducts: [ {
        name: assinaturaPresente.categoria.nome,
        category: assinaturaPresente.plano.tipo,
        price: assinaturaPresente.plano.valorTotal,
        quantity: '1',
      } ],
      transactionUser: [ {
        currency: 'BRL',
        email: presenteador.email,
        formaPagamento: assinaturaPresente.formaPagamento.visualizacao,
      } ],
    }

    const conversion = {
      conversionId: presenteador.cpf,
      conversionValue: assinaturaPresente.plano.valorTotal
    }

    dispatch( gtmPurchaseEvent( transaction ) )
    dispatch( gtmSubscribeEvent( transaction ) )
    dispatch( gtagConversionTracking( conversion ) )
  }
}

export const GTM_ASSINATURA_PRESENTE_PURCHASE = `GTM_ASSINATURA_PRESENTE_PURCHASE`
export const gtmPurchaseEvent = payload => {
  /**
   * Evento que indica uma compra de assinatura presente.
   */
  window.dataLayer.push( {
    event: GTM_ASSINATURA_PRESENTE_PURCHASE,
    payload
  } )
  return {
    type: GTM_ASSINATURA_PRESENTE_PURCHASE,
    payload
  }
}

export const GTM_ASSINATURA_PRESENTE_SUBSCRIBE = `GTM_ASSINATURA_PRESENTE_SUBSCRIBE`
export const gtmSubscribeEvent = payload => {
  /**
   * Evento que indica uma compra de assinatura presente como assinatura.
   */
  window.dataLayer.push( {
    event: GTM_ASSINATURA_PRESENTE_SUBSCRIBE,
    payload
  } )
  return {
    type: GTM_ASSINATURA_PRESENTE_SUBSCRIBE,
    payload
  }
}

export const GTM_PAGE_VIEW_SPA_EVENT = `pageViewSPA`
export const gtmPageViewSPAEvent = () => {
  /**
   * Evento que indica visualização de uma página de uma SPA.
   */
  window.dataLayer.push( { event: GTM_PAGE_VIEW_SPA_EVENT } )
  return { type: GTM_PAGE_VIEW_SPA_EVENT }
}


export const GTAG_CONVERSION = `GTAG_CONVERSION`
export const gtagConversionTracking = payload => {

  window.dataLayer.push( {
    event: GTAG_CONVERSION,
    payload
  } )

  return {
    type: GTAG_CONVERSION,
    payload
  }
}