import React, { Component } from 'react'
import Loading from '../loading'

export default function asyncComponent( getComponent, Loader=Loading ) {
  class AsyncComponent extends Component {
        static Component = null
        state = { Component: AsyncComponent.Component }

        componentWillMount() {
          if ( !this.state.Component ) {
            getComponent().then( Component => {
              AsyncComponent.Component = Component
              this.setState( { Component } )
            } )
          }
        }
        render() {
          const { Component } = this.state
          return Component ? <Component { ...this.props } /> : <Loader fullHeight />
        }
  }
  return AsyncComponent
}
