import { ORM } from 'redux-orm'

import AssinaturaPresente from '../funcionalidades/assinatura-presente/AssinaturaPresente'
import CartaoCredito from '../funcionalidades/cartao-credito/CartaoCredito'
import Categoria from '../funcionalidades/categoria/Categoria'
import Endereco from '../funcionalidades/endereco/Endereco'
import Etiqueta from '../funcionalidades/etiqueta/Etiqueta'
import Pessoa from '../funcionalidades/pessoa/Pessoa'
import Plano from '../funcionalidades/categoria/Plano'

const orm = new ORM()
orm.register(
  AssinaturaPresente,
  CartaoCredito,
  Categoria,
  Endereco,
  Etiqueta,
  Pessoa,
  Plano,
)

export default orm
