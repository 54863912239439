import { Model, attr } from 'redux-orm'

export default class Categoria extends Model {
  static get fields() {
    return {
      id: attr(),
      nome: attr(),
    }
  }

  static parse( dadosCategoria ) {
    return this.upsert( dadosCategoria )
  }

  toJSON() {
    return { ...this.ref }
  }
}

Categoria.modelName = `Categoria`
