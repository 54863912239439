import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bool, func, object, string } from 'prop-types'
import styled from 'styled-components'
import { Button, StyleGuide } from 'oef_componentes'

import PresenteadoResgateForm from '../funcionalidades/pessoa/PresenteadoResgateForm'
import EnderecoForm from '../funcionalidades/endereco/EnderecoForm'
import CodigoResgate from '../common/componentes/codigoResgate'

import { resgatarPresente, updateAssinaturaPresente, validarCodigo } from '../funcionalidades/assinatura-presente/actions'
import { updatePessoa } from '../funcionalidades/pessoa/actions'
import { updateEndereco } from '../funcionalidades/endereco/actions'
import { getSessaoEntidades } from '../funcionalidades/entidades/selectors'
import { empty } from '../shared/utils/validators'

import { redirectIfAuthorized } from '../shared/utils/auth'
import { URL_BASE_AREA_DO_ASSOCIADO } from '../shared/constants'

const { REACT_APP_CANONICAL_URL } = process.env


const actions = {
  resgatarPresente,
  updateAssinaturaPresente,
  updateEndereco,
  updatePessoa,
  validarCodigo,
}

const ResgateWrapper = styled.div `
  @media (min-width: ${StyleGuide.mediaQuery.tablet}) {
    display: flex;
    justify-content: space-between;
  }

  .section--wrapper__resgate {
    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      margin: 0 auto;
      max-width: 300px;
      width: 100%;
    }

    @media (min-width: ${StyleGuide.mediaQuery.tablet}) {
      width: calc((100%/2) - 40px);
    }
  }

  .wrapper--button__resgate {
    @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
      text-align: right;
    }
  }

  .form--section__resgate {
    .titulo--section__resgate {
      ${StyleGuide.tipografia.titulo3}
      margin-bottom: 20px;
    }
  }

  .info--section__resgate {
    color: ${StyleGuide.cores.cinza500};
    ${StyleGuide.defaultFonte}
    font-size: 20px;
    margin: 30px 0;

    span:last-of-type {
      display: flex;
      line-height: normal;
    }

    @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
      font-size: 18px;
    }
  }

  .numero--passo__resgate {
    align-items: center;
    border: 1.5px solid ${StyleGuide.cores.azul};
    border-radius: 100%;
    display: inline-flex;
    font-size: 11px;
    ${StyleGuide.boldFonte}
    float: left;
    justify-content: center;
    line-height: 20px;
    margin-right: 10px;
    width: 20px;

    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      display: none;
    }
  }
`

class Resgate extends Component {
  static propTypes = {
    assinaturaPresente: object,
    codigoValidado: bool,
    codigoValido: bool,
    endereco: object,
    erroCodigo: string,
    presenteado: object,
    resgatarPresente: func.isRequired,
    updateAssinaturaPresente: func.isRequired,
    updateEndereco: func.isRequired,
    updatePessoa: func.isRequired,
    validandoCodigo: bool,
    validarCodigo: func.isRequired,
  }

  static defaultProps = {
    assinaturaPresente: { plano: {} },
    codigoValidado: false,
    codigoValido: false,
    endereco: {},
    erroCodigo: '',
    presenteado: {},
    validandoCodigo: false,
  }

  componentWillMount() {
    redirectIfAuthorized( `${REACT_APP_CANONICAL_URL}${ URL_BASE_AREA_DO_ASSOCIADO }/presente/resgate` )
  }

  onBlurCodigo = event => {
    const codigo = event.target.value.toUpperCase()
    if ( !empty( codigo ) ) {
      this.props.validarCodigo( { codigo } )
    }
  }

  getErroCodigo() {
    const { codigoValido, erroCodigo, validandoCodigo } = this.props
    return !validandoCodigo && !codigoValido ? erroCodigo : undefined
  }

  onChangeCodigo = event => {
    const codigo = event.target.value.toUpperCase()
    this.props.updateAssinaturaPresente( { codigo } )
  }

  resgatarPresente = () => {
    const { presenteado, endereco } = this.props
    const errosPresenteado = presenteado.validar()
    const isPresenteadoValido = errosPresenteado.length === 0
    const errosEndereco = endereco.validar()
    const isEnderecoValido = errosEndereco.length === 0

    if ( isPresenteadoValido && isEnderecoValido ) {
      this.props.resgatarPresente()
    } else {
      if ( !isPresenteadoValido ) {
        this.props.updatePessoa( { ...presenteado.getRef(), erros: errosPresenteado } )
      }
      if ( !isEnderecoValido ) {
        this.props.updateEndereco( { ...endereco.toRef(), erros: errosEndereco } )
      }
    }
  }

  render() {
    const { assinaturaPresente, codigoValidado, codigoValido, endereco, presenteado } = this.props
    const periodo = assinaturaPresente.plano ? assinaturaPresente.plano.totalKits : `-`
    const textoPeriodo = assinaturaPresente.plano ? `${periodo} ${periodo === 1 ? 'mês' : 'meses'}` : `${periodo}`

    return (
      <ResgateWrapper sucesso={ codigoValidado ? codigoValido : undefined }>
        <section className={ `section--wrapper__resgate` }>
          <CodigoResgate
            assinaturaPresente={ assinaturaPresente }
            codigoValidado={ codigoValidado }
            codigoValido={ codigoValido }
            erroCodigo={ this.getErroCodigo() }
            onBlurCodigo={ this.onBlurCodigo.bind( this ) }
            onChangeCodigo={ this.onChangeCodigo.bind( this ) }
            periodo={ `${periodo}` }
            textoPeriodo={ textoPeriodo }
          />
        </section>
        <section className={ `section--wrapper__resgate` }>
          <div className={ `form--section__resgate` }>
            <p className={ `info--section__resgate` }>
              <span className={ `numero--passo__resgate` }>{ `2` }</span>
              <span>{ `Após informar o código, preencha e confirme os dados abaixo para garantirmos a entrega do seu presente.` }</span>
            </p>
            <h3 className={ `titulo--section__resgate` }>{ `Dados pessoais` }</h3>
            <PresenteadoResgateForm presenteado={ presenteado } />
          </div>
          <div className={ `form--section__resgate` }>
            <h3 className={ `titulo--section__resgate` }>{ `Endereço de entrega` }</h3>
            <EnderecoForm endereco={ endereco } />
          </div>
          <div className={ `wrapper--button__resgate` }>
            <Button onClick={ this.resgatarPresente.bind( this ) }>
              { `Confirmar` }
            </Button>
          </div>
        </section>
      </ResgateWrapper>
    )
  }
}

const mapStateToProps = store => {
  const { api: { codigoValidado, codigoValido, validandoCodigo } } = store
  const sessao = getSessaoEntidades( store )
  const { AssinaturaPresente, Endereco, Pessoa } = sessao

  const assinaturaPresente = AssinaturaPresente.all().first()
  const endereco = Endereco.all().first()
  const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO_RESGATE } )
  const erroCodigo = assinaturaPresente.erros ? assinaturaPresente.erros.codigo : ''

  return {
    assinaturaPresente,
    codigoValidado,
    codigoValido,
    endereco,
    erroCodigo,
    presenteado,
    validandoCodigo,
  }
}

export default connect(
  mapStateToProps,
  actions
)( Resgate )
