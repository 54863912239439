import { Storage } from '../storage'

const ERROR_TYPES = [ 400, 403, 404, 422 ]
const ERR_CONEXAO = 'Ocorreu um erro de conexão. Por favor, verifique seu dados e tente novamente.'
const ERR_CONTATO = 'Ocorreu um erro de conexão. Por favor, entre em contato por contato@taglivros.com.br.'

let unknownError = 0
export default class Fetch {
  constructor( { headers } ) {
    this.headers = headers ? headers : new Headers()
    this.headers.append( 'Content-Type', 'application/json' )
    let tokenFromStorage = Storage.getToken()
    if ( tokenFromStorage ) {
      this.headers.append( 'Authorization', `Bearer ${tokenFromStorage}` )
    }
  }

  fetch( { url, body, method } ) {
    const options = {
      method,
      headers: this.headers,
      body,
    }
    return fetch( url, options )
      .then( response => {
        if ( !response.ok ) {
          return Promise.reject( response )
        }
        return response
      } )
  }

  _fetch( { url, body, method } ) {
    return this.fetch( { url, body: JSON.stringify( body ), method } )
      .then( response => 'json' in response ? response.json() : {} )
      .catch( error => {
        if ( error.status === 401 ) {
          if ( window.location.href.includes( 'area-do-associado' ) ) {
            window.location.href = '/logout'
            return
          } else if ( !( window.location.href.includes( 'login' ) || window.location.href.includes( 'logout' ) ) ) {
            Storage.clear()
            this.headers.delete( 'Authorization' )
            return this._fetch( { url, body, method } )
          }
        }
        if ( !ERROR_TYPES.includes( error.status ) ) {
          unknownError += 1
          let message = unknownError >= 2 ? ERR_CONTATO : ERR_CONEXAO
          return Promise.reject( { errors: [ { message } ] } )
        }
        return error.json()
          .then( json => {
            return Promise.reject( { ...json, status: error.status } )
          } )
      } )
  }

  get( { url, body } ) {
    return this._fetch( { url, body, method: 'GET' } )
  }

  post( { url, body } ) {
    return this._fetch( { url, body, method: 'POST' } )
  }

  patch( { url, body } ) {
    return this._fetch( { url, body, method: 'PATCH' } )
  }

  delete( { url, body } ) {
    return this.fetch( { url, body, method: 'DELETE' } )
      .then( response => response.text() )
  }
}
