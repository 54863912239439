const mockCategorias = {
  "categorias": [
      {
          "id": 1,
          "planos": [
              {
                  "valor_total": 670.8,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Anual",
                  "valor_por_kit": 55.9,
                  "id": 3,
                  "fidelidade": false,
                  "total_kits": 12,
                  "presente": "False"
              },
              {
                  "valor_total": 79.9,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Mensal",
                  "valor_por_kit": 79.9,
                  "id": 7,
                  "fidelidade": false,
                  "total_kits": 1,
                  "presente": "True"
              },
              {
                  "valor_total": 218.7,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Trimestral",
                  "valor_por_kit": 72.9,
                  "id": 8,
                  "fidelidade": false,
                  "total_kits": 3,
                  "presente": "True"
              },
              {
                  "valor_total": 437.4,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Semestral",
                  "valor_por_kit": 72.9,
                  "id": 9,
                  "fidelidade": false,
                  "total_kits": 6,
                  "presente": "True"
              },
              {
                  "valor_total": 790.8,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Anual",
                  "valor_por_kit": 65.9,
                  "id": 10,
                  "fidelidade": false,
                  "total_kits": 12,
                  "presente": "True"
              },
              {
                  "valor_total": 55.9,
                  "formas_pagamento": [
                      "Cartão de Crédito"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Mensal",
                  "valor_por_kit": 55.9,
                  "id": 15,
                  "fidelidade": true,
                  "total_kits": 1,
                  "presente": "False"
              },
              {
                  "valor_total": 167.7,
                  "formas_pagamento": [
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Trimestral",
                  "valor_por_kit": 55.9,
                  "id": 17,
                  "fidelidade": true,
                  "total_kits": 3,
                  "presente": "False"
              },
              {
                  "valor_total": 65.9,
                  "formas_pagamento": [
                      "Cartão de Crédito"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Mensal",
                  "valor_por_kit": 65.9,
                  "id": 1,
                  "fidelidade": false,
                  "total_kits": 1,
                  "presente": "False"
              },
              {
                  "valor_total": 197.7,
                  "formas_pagamento": [
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Trimestral",
                  "valor_por_kit": 65.9,
                  "id": 2,
                  "fidelidade": false,
                  "total_kits": 3,
                  "presente": "False"
              },
              {
                  "valor_total": 395.4,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Curadoria",
                  "tipo_plano": "Semestral",
                  "valor_por_kit": 65.9,
                  "id": 19,
                  "fidelidade": false,
                  "total_kits": 6,
                  "presente": "False"
              }
          ],
          "produtos": [
              "Livro em edição exclusiva (capa dura)",
              "Revista sobre autor e obra",
              "Box colecionável",
              "Mimo literário",
              "Marcador de página",
              "Aplicativo exclusivo",
              "Playlist personalizada"
          ],
          "nome": "Curadoria"
      },
      {
          "id": 2,
          "planos": [
              {
                  "valor_total": 45.9,
                  "formas_pagamento": [
                      "Cartão de Crédito"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Mensal",
                  "valor_por_kit": 45.9,
                  "id": 16,
                  "fidelidade": true,
                  "total_kits": 1,
                  "presente": "False"
              },
              {
                  "valor_total": 137.7,
                  "formas_pagamento": [
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Trimestral",
                  "valor_por_kit": 45.9,
                  "id": 18,
                  "fidelidade": true,
                  "total_kits": 3,
                  "presente": "False"
              },
              {
                  "valor_total": 69.9,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Mensal",
                  "valor_por_kit": 69.9,
                  "id": 11,
                  "fidelidade": false,
                  "total_kits": 1,
                  "presente": "True"
              },
              {
                  "valor_total": 670.8,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Anual",
                  "valor_por_kit": 55.9,
                  "id": 14,
                  "fidelidade": false,
                  "total_kits": 12,
                  "presente": "True"
              },
              {
                  "valor_total": 188.7,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Trimestral",
                  "valor_por_kit": 62.9,
                  "id": 12,
                  "fidelidade": false,
                  "total_kits": 3,
                  "presente": "True"
              },
              {
                  "valor_total": 377.4,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Semestral",
                  "valor_por_kit": 62.9,
                  "id": 13,
                  "fidelidade": false,
                  "total_kits": 6,
                  "presente": "True"
              },
              {
                  "valor_total": 562.8,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Anual",
                  "valor_por_kit": 46.9,
                  "id": 6,
                  "fidelidade": false,
                  "total_kits": 12,
                  "presente": "False"
              },
              {
                  "valor_total": 55.9,
                  "formas_pagamento": [
                      "Cartão de Crédito"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Mensal",
                  "valor_por_kit": 55.9,
                  "id": 4,
                  "fidelidade": false,
                  "total_kits": 1,
                  "presente": "False"
              },
              {
                  "valor_total": 167.7,
                  "formas_pagamento": [
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Trimestral",
                  "valor_por_kit": 55.9,
                  "id": 5,
                  "fidelidade": false,
                  "total_kits": 3,
                  "presente": "False"
              },
              {
                  "valor_total": 335.4,
                  "formas_pagamento": [
                      "Cartão de Crédito",
                      "Boleto"
                  ],
                  "categoria": "Inéditos",
                  "tipo_plano": "Semestral",
                  "valor_por_kit": 55.9,
                  "id": 20,
                  "fidelidade": false,
                  "total_kits": 6,
                  "presente": "False"
              }
          ],
          "produtos": [
              "Livro em edição exclusiva (brochura)",
              "Box colecionável",
              "Revista sobre autor e obra",
              "Mimo literário",
              "Marcador de página",
              "Aplicativo exclusivo",
              "Playlist personalizada"
          ],
          "nome": "Inéditos"
      }
  ]
}

export  {
  mockCategorias
}