import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { StyleGuide } from 'oef_componentes'

const { REACT_APP_CANONICAL_URL } = process.env

const Nav = styled.nav`
  align-items: center;
  background-color: #fff;
  box-shadow: ${props => props.hasChildren ? '0 4px 2px -2px' : '0 2px 4px 0'} rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: ${props => props.hasChildren ? 'space-between' : 'center'};
  margin: auto;
  padding: 30px 0;
  width: ${props => props.hasChildren ? `66%` : `100%`};

  .renderedChildren {
    display: ${props => props.hasChildren ? 'flex' : 'none'};
    flex-grow: 1;
    justify-content: flex-end;
    position: relative;

    @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
      max-width: 600px;
    }

    ul {
      @media (max-width: ${StyleGuide.mediaQuery.tablet}) {
        max-width: 120px;
      }
    }
  }

  .link--logo__nav {
    margin-top: 20px;

    @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
      margin-right: 10px;
    }

    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      margin-top: 15px;
    }
  }

  .link--logo__nav span {
    font-size: 2em;

    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      font-size: 1.75em;
    }
  }

  @media ( max-width: ${StyleGuide.mediaQuery.mobile} ) {
    padding: 15px 0;
    width: ${props => props.hasChildren ? `90%` : `100%`};
  }
`

class Header extends Component {
  static propTypes = {
    children: PropTypes.element,
  }

  static defaultProps = {
    children: undefined,
    imageSize: '250',
    imagePositionY: '0',
    textTitle: 'Título',
    textSubtitle: 'Subtítulo'
  }

  render() {
    const { children } = this.props
    return (
      <nav>
        <Nav hasChildren={ !!children }>
          <a
            className="link--logo__nav"
            href={ REACT_APP_CANONICAL_URL }
          >
            <span className="icon-tag-brand">
              <span className="path1" />
              <span className="path2" />
              <span className="path3" />
              <span className="path4" />
              <span className="path5" />
              <span className="path6" />
            </span>
          </a>
          <div className="renderedChildren">
            {children}
          </div>
        </Nav>
      </nav>
    )
  }
}

export default Header
