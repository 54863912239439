import encrypt from 'adyen-cse-js/js/adyen.encrypt.nodom.min.js'

const { REACT_APP_CHAVE_CRIPTO_ADYEN } = process.env

export class AdyenGateway {

  constructor() {
    let key = `${REACT_APP_CHAVE_CRIPTO_ADYEN}`
    let options = {}
    this.cseInstance = encrypt.createEncryption( key, options )
  }

  _getCheckoutObject( cartaoCredito ) {
    let exp_month = cartaoCredito.dt_validade.substring( 0, 2 )
    let exp_year = 2000 + parseInt( cartaoCredito.dt_validade.substring( 2 ), 10 )
    return {
      number: cartaoCredito.numero,
      cvc: cartaoCredito.cvv,
      holderName: cartaoCredito.nome,
      expiryMonth: exp_month,
      expiryYear: exp_year.toString(),
      generationtime: new Date().toISOString()
    }
  }

  checkout( cartaoCredito ) {
    let exp_month = cartaoCredito.dt_validade.substring( 0, 2 )
    let exp_year = cartaoCredito.dt_validade.substring( 2 )
    let validateCard = {
      number: cartaoCredito.numero,
      cvc: cartaoCredito.cvv,
      month: exp_month,
      year: '20' + exp_year,
    }
    if ( this.cseInstance.validate( validateCard ).valid ) {
      let response = {}
      response.id = this.cseInstance.encrypt( this._getCheckoutObject( cartaoCredito ) )
      response.holder_name = cartaoCredito.nome
      response.last_four_digits = cartaoCredito.numero.substr( -4 )
      response.exp_month = parseInt( exp_month, 10 )
      response.exp_year = parseInt( exp_year, 10 )
      return Promise.resolve( response )
    } else {
      let validationResult = this.cseInstance.validate( validateCard )
      let errors = []
      if ( !validationResult.number ) {
        errors.push( { 'source': 'numero', 'message': 'Número Inválido.' } )
      }
      if ( !validationResult.cvc ) {
        errors.push( { 'source': 'cvv', 'message': 'CVV Inválido.' } )
      }
      if ( !validationResult.expiryMonth || !validationResult.expiryYear ) {
        errors.push( { 'source': 'dt_validade', 'message': 'Validade Inválida.' } )
      }
      return Promise.reject( { source: 'gateway', errors } )
    }
  }
}
