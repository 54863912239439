import { normalizeString } from '../utils/formatters'
import { TYPE_SELECIONAR_CATEGORIA_CURADORIA, TYPE_SELECIONAR_CATEGORIA_INEDITOS } from '../components/button'
import curadoriaIcon from '../../images/svg/icon-curadoria.svg'
import ineditosIcon from '../../images/svg/icon-ineditos.svg'
import case2020 from '../../images/case.png'

export const validStates = [ 'AC', 'AL', 'AM', 'AP', 'BA', 'CE',
  'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB',
  'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC',
  'SE', 'SP', 'TO' ]

export const sexoOptions = [
  {
    'codigo': 'F',
    'visualizacao': 'No feminino',
    'value': 'F'
  },
  {
    'codigo': 'M',
    'visualizacao': 'No masculino',
    'value': 'M'
  },
  {
    'codigo': 'Q',
    'visualizacao': 'Com qualquer uma das opções',
    'value': 'Q'
  },
  {
    'codigo': 'N',
    'visualizacao': 'Com nenhuma das opções',
    'value': 'N'
  },
]

export const escolhaPronomeTratamento = [
  {
    'codigo': 'S',
    'visualizacao': 'Selecione',
    'value': ''
  },
  ...sexoOptions
]

export const CATEGORIA_INEDITOS = 'INÉDITOS'
export const CATEGORIA_CURADORIA = 'CURADORIA'

export const PLANO_ANUAL = 'anual'
export const PLANO_TRIMESTRAL = 'trimestral'
export const PLANO_MENSAL = 'mensal'
export const PLANO_SEMESTRAL = 'semestral'

export const TIPO_BOLETO = 'boleto'
export const TIPO_BOLETO_BACKEND = 'B'
export const TIPO_CREDITO = 'cartao_credito'
export const TIPO_CREDITO_BACKEND = 'C'


export const URL_CORREIOS = `http://www.buscacep.correios.com.br/sistemas/buscacep/`

//associe-se
export const URL_BASE_AREA_DO_ASSOCIADO = `/area-do-associado`
export const URL_BASE_ASSOCIE_SE = `/associe-se`
export const URL_CATEGORIAS = `${URL_BASE_ASSOCIE_SE}/escolha-sua-caixinha`
export const URL_PLANOS = categoria => `${URL_BASE_ASSOCIE_SE}/${normalizeString( categoria )}/selecionar-plano`
export const URL_IDENTIFICACAO = ( categoria, plano ) => `${URL_BASE_ASSOCIE_SE}/${normalizeString( categoria )}/${normalizeString( plano )}/identificacao`
export const URL_ENTREGA = ( categoria, plano ) => `${URL_BASE_ASSOCIE_SE}/${normalizeString( categoria )}/${normalizeString( plano )}/entrega`
export const URL_PAGAMENTO = ( categoria, plano ) => `${URL_BASE_ASSOCIE_SE}/${normalizeString( categoria )}/${normalizeString( plano )}/pagamento`
export const URL_ASSINATURA_COMPLETA = ( categoria, plano ) => `${URL_BASE_ASSOCIE_SE}/${normalizeString( categoria )}/${normalizeString( plano )}/assinatura-concluida`
export const URL_ONBOARDING = ( categoria, plano ) => `${URL_BASE_ASSOCIE_SE}/${normalizeString( categoria )}/${normalizeString( plano )}/on-boarding`
export const IDENTIFICACAO_TITLE = `Preencha seus dados pessoais.`

//area do associado
export const URL_BASE_LOGIN = '/login'

//Cartões
export const BANDEIRAS_VALIDAS_VIEW = [ 'VISA', 'MASTERCARD', 'AMEX', 'ELO', 'HIPERCARD' ]

//Categoria Infos
export const CATEGORIAS_INFOS = {
  [ CATEGORIA_CURADORIA ]: {
    features: [
      `Livro em edição exclusiva (capa dura)`,
      `Revista sobre autor e obra`,
      `Box colecionável`,
      `Mimo literário`,
      `Marcador de página`,
    ],
    rewards: {
      anual: {
        discount: '15%',
        image: case2020,
        tagline: 'Brinde extra para plano anual.'
      }
    }
  },
  [ CATEGORIA_INEDITOS ]: {
    features: [
      `Livro em edição exclusiva (brochura)`,
      `Revista sobre autor e obra`,
      `Box colecionável`,
      `Mimo literário`,
      `Marcador de página`,
    ],
    rewards: {
      anual: {
        discount: '15%',
        image: case2020,
        tagline: 'Brinde extra para plano anual.'
      }
    }
  },
}

export const NUMERO_PARCELAS = [ 1, 6, 12 ]

//Utils
export const CATEGORIAS = {
  [ CATEGORIA_CURADORIA ]: {
    text: 'Livros indicados por grandes nomes da literatura mundial',
    infos: [
      'Livro em edição exclusiva (capa dura)',
      'Revista sobre autor e obra',
      'Box colecionável',
      'Mimo literário',
      'Marcador de página',
      'Aplicativo exclusivo',
      'Playlist personalizada'
    ],
    buttonType: TYPE_SELECIONAR_CATEGORIA_CURADORIA,
    buttonText: 'Quero TAG Curadoria',
    icon: 'curadoria',
    iconPath: curadoriaIcon,
    plan: 'curadoria'
  },
  [ CATEGORIA_INEDITOS ]: {
    text: 'Livros best-sellers mais viciantes e ainda inéditos no Brasil',
    infos: [
      'Livro em edição exclusiva (brochura)',
      'Revista sobre autor e obra',
      'Box colecionável',
      'Mimo literário',
      'Marcador de página',
      'Aplicativo exclusivo',
      'Playlist personalizada'
    ],
    buttonType: TYPE_SELECIONAR_CATEGORIA_INEDITOS,
    buttonText: 'Quero TAG Inéditos',
    icon: 'ineditos',
    iconPath: ineditosIcon,
    plan: 'ineditos'
  }
}

//Youtube Links
export const YOUTUBE_THUMBMAIL_LINK = videoId => `//i1.ytimg.com/vi/${videoId}/sddefault.jpg`

//UTM_SOURCE
export const UTM_SOURCE_MULTIPLUS = 'multiplus'

// Constantes cobranças
export const SALDO_INSUFICIENTE = 'Este cartão não tem saldo suficiente para a compra.'

// PROMOÇÕES
export const CRIACAO_ASSINATURA = `CRIACAO_ASSINATURA`
export const PROMOCAO_ACAO_VARIAVEL = `Ação variável`
export const PROMOCAO_DESCONTO = `Desconto`
export const PROMOCAO_FREETRIAL = `Free Trial`
export const PROMOCAO_VENDA_BONIFICADA = `Venda Bonificada`
