import { connect } from 'react-redux'
import { Storage } from '../shared/utils/storage'
import { empty, isEmptyObject } from '../shared/utils/validators'
import { mockCategorias } from './mock'

const { REACT_APP_API_BASE_URL } = process.env

const requisicao = ( { url, method, body } ) => {
  const headers = new Headers()
  headers.append( `Content-Type`, `application/json` )
  const token = Storage.getToken()
  if ( token ) {
    headers.append( 'Authorization', `Bearer ${token}` )
  }
  const options = {
    headers,
    method: method,
    body: !empty( body ) && !isEmptyObject( body ) ? JSON.stringify( body ) : undefined
  }
  return fetch( url, options )
    .then( async resposta => {
      if ( resposta.ok ) {
        let json
        try {
          json = await resposta.json()
        } catch ( e ) {
          json = {}
        }
        return json
      } else if ( resposta.status === 500 ) {
        throw { errors: [ { message: 'Ocorreu um problema no servidor.' } ] }
      } else {
        throw resposta.json()
      }
    } )
}

const validarLead = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/valida-lead`,
    method: `POST`,
    body: payload,
  } )
}

const buscarCategorias = () => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/categorias`,
    method: `GET`
  } ).then( ( { categorias: categoriasAPI } ) => {
    console.log(mockCategorias)
    const categorias = mockCategorias.categorias.map( categoria => {
      return {
        ...categoria,
        planos: categoria.planos.filter( plano => plano.presente === `True` )
      }
    } )
    return { categorias}
  } )
}

const salvarDadosPessoais = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/dados-pessoais`,
    method: `POST`,
    body: payload,
  } )
}

const salvarDadosEndereco = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/endereco`,
    method: `POST`,
    body: payload,
  } )
}

const comprar = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/pagamento`,
    method: `POST`,
    body: payload,
  } )
}

const validarCodigo = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/codigo`,
    method: `POST`,
    body: payload,
  } )
}

const validarEmailResgate = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/email`,
    method: `POST`,
    body: payload,
  } )
}

const validarCPFResgate = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/cpf`,
    method: `POST`,
    body: payload,
  } )
}

const resgatar = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/resgate`,
    method: `POST`,
    body: payload,
  } )
}

const completarCadastroPresenteado = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/associado`,
    method: `PATCH`,
    body: payload,
  } )
}

const enviarEmailPDF = payload => {
  return requisicao( {
    url: `${REACT_APP_API_BASE_URL}/v2/assinatura-presente/envia-email-presente-cartinha`,
    method: `POST`,
    body: payload,
  } )
}

export default {
  buscarCategorias,
  completarCadastroPresenteado,
  comprar,
  enviarEmailPDF,
  resgatar,
  salvarDadosPessoais,
  salvarDadosEndereco,
  validarCodigo,
  validarCPFResgate,
  validarEmailResgate,
  validarLead,
}
