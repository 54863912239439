import { Model, attr } from 'redux-orm'

import { empty, sanitizeValue } from '../../shared/utils/validators'

export default class Endereco extends Model {
  static get fields() {
    return {
      id: attr(),
      idOEF: attr(),
      _cep: attr(),
      uf: attr(),
      cidade: attr(),
      bairro: attr(),
      rua: attr(),
      numero: attr(),
      complemento: attr(),
      ibge: attr(),
      erros: attr(),
    }
  }

  static parse( dadosEndereco ) {
    const { cep, ...dados } = dadosEndereco
    const _cep = sanitizeValue( cep ).replace( /\D/g, '' ).substring( 0, 8 )
    return this.upsert( { erros: [], _cep, ...dados } )
  }

  get camposObrigatorios() {
    return [ 'cep', 'uf', 'cidade', 'bairro', 'rua', 'numero' ]
  }

  get cep() {
    if ( empty( this._cep ) ) {
      return
    } else if ( this._cep.length <= 5 ) {
      return this._cep
    } else {
      return `${this._cep.substring( 0, 5 )}-${this._cep.substring( 5 )}`
    }
  }

  get cleanCEP() {
    return this._cep
  }

  getErro( campo ) {
    const erro = this.erros.find( erro => erro.campo === campo )
    return erro ? erro.mensagem : ``
  }

  validarCampo( campo ) {
    let mensagem = ``
    if ( this.camposObrigatorios.includes( campo ) && empty( this[ campo ] ) ) {
      mensagem = `Campo obrigatório.`
    } else if ( campo === `cep` && this._cep.length < 8 ) {
      mensagem = `CEP deve conter 8 dígitos`
    }
    return { campo, mensagem }
  }

  validar() {
    const erros = []
    for ( let campo of this.camposObrigatorios ) {
      if ( empty( this[ campo ] ) ) {
        erros.push( { campo, mensagem: `Campo obrigatório.` } )
      }
    }
    if ( this.cep && this._cep.length < 8 ) {
      erros.push( { campo: `cep`, mensagem: `CEP deve conter 8 dígitos.` } )
    }
    return erros
  }

  toRef() {
    const { _cep: cep, ...ref } = this.ref
    return { cep, ...ref }
  }

  toJSON() {
    const {
      idOEF: id,
      _cep: cep,
      uf,
      cidade,
      bairro,
      rua,
      numero,
      complemento,
      ibge,
    } = this.ref
    return {
      id,
      cep,
      uf,
      cidade,
      bairro,
      rua,
      numero,
      complemento,
      ibge,
    }
  }
}

Endereco.modelName = `Endereco`
