import { Model, attr } from 'redux-orm'
import Payment from 'payment'
import { PaymentGatewayFactory } from '../../shared/utils/payments/PaymentGatewayFactory'
import { empty, sanitizeValue } from '../../shared/utils/validators'

export default class CartaoCredito extends Model {
  static get fields() {
    return {
      id: attr(),
      _numero: attr(),
      nome: attr(),
      _vencimento: attr(),
      cvv: attr(),
      bandeira: attr(),
      token: attr(),
      ultimosDigitos: attr(),
      erros: attr(),
    }
  }

  static parse( dadosCartaoCredito ) {
    const { numero, vencimento, nome=``, cvv=``, erros=[], ...dados } = dadosCartaoCredito
    const _numero = sanitizeValue( numero ).replace( /\D/g, '' ).substring( 0, 16 )
    const _vencimento = sanitizeValue( vencimento ).replace( /\D/g, '' ).substring( 0, 4 )
    return this.upsert( {
      _numero,
      _vencimento,
      nome: nome.toUpperCase().replace( /\d/g, '' ),
      cvv: cvv.replace( /\D/g, '' ),
      erros,
      ...dados
    } )
  }

  get camposObrigatorios() {
    return [ 'numero', 'nome', 'vencimento', 'cvv' ]
  }

  get numero() {
    if ( empty( this._numero ) ) {
      return
    } else if ( this._numero.length <= 4 ) {
      return this._numero
    } else if ( this._numero.length <= 8 ) {
      return `${this._numero.substring( 0, 4 )} ${this._numero.substring( 4 )}`
    } else if ( this._numero.length <= 12 ) {
      return `${this._numero.substring( 0, 4 )} ${this._numero.substring( 4, 8 )} ${this._numero.substring( 8 )}`
    } else {
      return `${this._numero.substring( 0, 4 )} ${this._numero.substring( 4, 8 )} ${this._numero.substring( 8, 12 )} ${this._numero.substring( 12 )}`
    }
  }

  get vencimento() {
    if ( empty( this._vencimento ) ) {
      return
    } else if ( this._vencimento.length <= 2 ) {
      return this._vencimento
    } else {
      return `${this._vencimento.substring( 0, 2 )}/${this._vencimento.substring( 2 )}`
    }
  }

  _getMensagemErroVencimento() {
    if ( this._vencimento.length < 4 ) {
      return `Formato de data inválido. Favor usar o formato MM/AA.`
    } else {
      const mes = parseInt( this._vencimento.substring( 0, 2 ), 10 ) - 1
      const mesCorrente = new Date().getUTCMonth()
      const ano = 2000 + parseInt( this._vencimento.substring( 2 ), 10 )
      const anoCorrente = new Date().getUTCFullYear()
      if ( ano < anoCorrente || ( ano === anoCorrente && mes < mesCorrente ) ) {
        return `Data inválida.`
      } else {
        return ''
      }
    }
  }

  getErro( campo ) {
    const erro = this.erros.find( erro => erro.campo === campo )
    return erro ? erro.mensagem : ``
  }

  getErros( erros ) {
    if ( erros.every( e1 => this.erros.find( e2 => e1.campo === e2.campo ) ) ) {
      // Se todos os campos dos novos erros já existem nos erros do cartão de credito, basta substituir
      return this.erros.map( erro => erros.find( e => e.campo === erro.campo ) || erro )
    } else {
      // Se existe algum novo campo de erro nos novos erros, precisamos juntar os dois arrays
      return [ ...erros, ...this.erros ].reduce(
        ( acc, erro ) => {
          acc.find( e => e.campo === erro.campo ) ?
            acc.map( e => e.campo === erro.campo ? erro : e ) :
            acc.concat( erro )
        }, [] )
    }
  }

  getRef() {
    const { _numero: numero, _vencimento: vencimento, ...ref } = this.ref
    return { numero, vencimento, ...ref }
  }

  validarCampo( campo ) {
    let mensagem = ``
    if ( this.camposObrigatorios.includes( campo ) && empty( this[ campo ] ) ) {
      mensagem = `Campo obrigatório.`
    } else if ( campo === `numero` && this._numero.length < 14 ) {
      mensagem = `Número deve conter ao menos 14 dígitos.`
    } else if ( campo === `vencimento` ) {
      mensagem = this._getMensagemErroVencimento()
    } else if ( campo === `cvv` && this.cvv.length < 3 ) {
      mensagem = `CVV deve conter 3 ou 4 dígitos.`
    }
    return { campo, mensagem }
  }

  validar() {
    const erros = []
    for ( let campo of this.camposObrigatorios ) {
      if ( empty( this[ campo ] ) ) {
        erros.push( { campo, mensagem: `Campo obrigatório.` } )
      } else if ( campo === `numero` && this._numero.length < 14 ) {
        erros.push( { campo, mensagem: `Número deve conter ao menos 14 dígitos.` } )
      } else if ( campo === `vencimento` ) {
        const mensagem = this._getMensagemErroVencimento()
        if ( !empty( mensagem ) ) {
          erros.push( { campo, mensagem } )
        }
      } else if ( campo === `cvv` && this.cvv.length < 3 ) {
        erros.push( { campo, mensagem: `CVV deve conter 3 ou 4 dígitos.` } )
      }
    }
    return erros
  }

  async tokenizar() {
    const paymentGateway = PaymentGatewayFactory.create()
    try {
      const {
        holder_name: nome,
        exp_year: anoVencimento,
        exp_month: mesVencimento,
        last_four_digits: ultimosDigitos,
        id: token
      } = await paymentGateway.checkout( {
        numero: this._numero,
        nome: this.nome,
        cvv: this.cvv,
        dt_validade: this._vencimento,
      } )
      const bandeira = Payment.fns.cardType( this._numero )
      return {
        anoVencimento: 2000 + anoVencimento,
        bandeira,
        mesVencimento,
        nome,
        token,
        ultimosDigitos,
      }
    } catch ( { errors } ) {
      const erros = errors.map( ( { source: campo, message: mensagem='' } ) => {
        if ( campo === `dt_validade` ) {
          campo = `vencimento`
        }
        return { campo, mensagem }
      } )
      throw erros
    }
  }

  toJSON() {
    const { _numero: numero, _vencimento: vencimento, ...ref } = this.ref
    return { numero, vencimento, ...ref }
  }
}

CartaoCredito.modelName = `CartaoCredito`
