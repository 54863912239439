import isMobile from './device'
import unorm from 'unorm'

export const formatParcelas = ( parcelas, total, selected ) => {
  return parcelas.map( option => {
    let value = formatValor( { valor: total / option } )

    return {
      name: `${option}x de ${value}`,
      value: option,
      selected: option == selected
    }
  } )
}

export const formatTelefone = ( telefone ) => {
  if ( !telefone ) {
    return ''
  }
  let dotPos = telefone.length > 10 ? '7' : '6'
  if ( telefone.length <= 2 ) {
    return `(${telefone}`
  } else if ( telefone.length <= dotPos ) {
    return `(${telefone.substring( 0, 2 )}) ${telefone.substring( 2 )}`
  } else {
    return `(${ telefone.substring( 0, 2 ) }) ${ telefone.substring( 2, dotPos ) }.${ telefone.substring( dotPos ) }`
  }
}

export const formatValor = ( { valor } ) => {
  if ( valor ) {
    valor = parseFloat( valor, 10 )
    if ( typeof ( Intl ) !== 'undefined' && Intl.NumberFormat ) {
      return new Intl.NumberFormat( 'pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      } ).format( valor )
    } else {
      valor = valor.toFixed( 2 ).toString()
    }
    return `R$${ valor.replace( '.', ',' ) }`
  } else {
    if ( valor === 0 ) {
      return `R$ 0,00`
    }
    return ''
  }
}

export const formatNumeroCartao = ( { bandeira, ultimosDigitos } ) => {
  if ( !ultimosDigitos ) {
    return '**** **** **** ****'
  } else if ( bandeira === 'amex' ) {
    return `${isMobile() ? 'Final ' : '**** **** ***'}${ultimosDigitos.substring( 0, 1 )} ${ultimosDigitos.substring( 1 )}`
  } else {
    return `${isMobile() ? 'Final ' : '**** **** ****'} ${ultimosDigitos}`
  }
}

export const formatterArray = array => {
  let lastIndex = array.length - 1

  return array.reduce( ( acc, curr, i ) => {
    let text
    switch ( i ) {
      case lastIndex:
        text = `${curr}.`
        break

      case lastIndex - 1:
        text = `, ${curr} e `
        break

      default:
        text = `, ${curr}`
        break
    }

    return `${acc}${text}`
  }, '' )
}

export const normalizeNumberString = ( str ) => {
  if ( !str ) {
    return ''
  } else {
    return str.replace( /\D/g, '' )
  }
}

export const formatNumberTwoDigits = number => ( '00' + number ).slice( -2 )

export const capitalize = str => str ? `${str[ 0 ].toUpperCase()}${str.substring( 1 ).toLowerCase()}` : ``

export const normalizeString = ( str='' ) => unorm.nfd( str.toLowerCase() ).replace( /[\u0300-\u036f]/g, '' )

export const capitalizeFirstLetters = ( str ) => {
  const completeNameReGexp = /^([A-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'-.])+(\s[_A-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'-. ]+)+$/g
  if ( str && str !== '' ) {
    if ( completeNameReGexp.test( str ) ) {
      const nomes = str.split( ' ' )
      return nomes.map( nome => nome.charAt( 0 ).toUpperCase() + nome.slice( 1 ) ).join( ' ' )
    } else {
      return str
    }
  }
}
