import { createConditionalSliceReducer } from '../../common/utils/reducerUtils'
import orm from '../../schema/schema'

const updatePessoa = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Pessoa } = sessao

  Pessoa.parse( payload )

  return sessao.state
}

const completarPassoIdentificacao = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Pessoa } = sessao

  const { presenteado, presenteador } = payload

  const presenteadoExistente = Pessoa.get( { tipo: Pessoa.PRESENTEADO } )
  const idOEFPresenteado = presenteado.id
  Pessoa.parse( { idOEF: idOEFPresenteado, ...presenteadoExistente.getRef() } )

  const presenteadorExistente = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )
  const idOEFPresenteador = presenteador.id
  Pessoa.parse( { idOEF: idOEFPresenteador, ...presenteadorExistente.getRef() } )

  return sessao.state
}

const receiveErros = ( state, payload, tipoPessoa ) => {
  const sessao = orm.session( state )
  const { Pessoa } = sessao

  const pessoa = Pessoa.get( { tipo: tipoPessoa } )

  const errosBackend = payload.errors.map( ( { source: campo, message: mensagem } ) => ( { campo, mensagem } ) )
  const erros = pessoa.getErros( errosBackend )

  Pessoa.parse( { ...pessoa.getRef(), erros } )

  return sessao.state
}

const receiveErrosPresenteador = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Pessoa } = sessao

  return receiveErros( state, payload, Pessoa.PRESENTEADOR )
}

const receiveErrosEmailPresenteadoCompra = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Pessoa } = sessao

  return receiveErros( state, payload, Pessoa.PRESENTEADO )
}

const receiveErrosPresenteadoResgate = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Pessoa } = sessao

  return receiveErros( state, payload, Pessoa.PRESENTEADO_RESGATE )
}

export default createConditionalSliceReducer( 'entities', {
  UPDATE_PESSOA: updatePessoa,
  COMPLETAR_PASSO_IDENTIFICACAO: completarPassoIdentificacao,
  RECEIVE_ERROS_PRESENTEADOR: receiveErrosPresenteador,
  RECEIVE_ERROS_EMAIL_PRESENTEADO_COMPRA: receiveErrosEmailPresenteadoCompra,
  RECEIVE_ERROS_PRESENTEADO_RESGATE: receiveErrosPresenteadoResgate,
} )
