import { Model, attr, fk } from 'redux-orm'

import { empty, isValidEmail, sanitizeValue, validarCPF, validateTelephoneNumber, isEmptyObject } from '../../shared/utils/validators'
import TitleClass from '../../shared/utils/titleCaps'

export default class Pessoa extends Model {
  static PRESENTEADOR = `presenteador`
  static PRESENTEADO = `presenteado`
  static PRESENTEADO_RESGATE = `presenteadoResgate`
  static TIPOS_VALIDOS = [ Pessoa.PRESENTEADOR, Pessoa.PRESENTEADO, Pessoa.PRESENTEADO_RESGATE ]

  static MASCULINO = { visualizacao: `No masculino`, codigo: `M`, value: `M` }
  static FEMININO = { visualizacao: `No feminino`, codigo: `F`, value: `F` }
  static QUALQUER_OPCAO = { visualizacao: `Com qualquer uma das opções`, codigo: `Q`, value: `Q` }
  static NENHUMA_OPCAO = { visualizacao: `Com nenhuma das opções`, codigo: `N`, value: `N` }
  static GENEROS_VALIDOS = [ Pessoa.MASCULINO, Pessoa.FEMININO, Pessoa.QUALQUER_OPCAO, Pessoa.NENHUMA_OPCAO ]

  static get fields() {
    return {
      id: attr(),
      idOEF: attr(),
      tipo: attr(),
      nome: attr(),
      email: attr(),
      _cpf: attr(),
      _telefone: attr(),
      genero: attr(),
      senha: attr(),
      erros: attr(),
      assinatura: fk( `AssinaturaPresente` ),
    }
  }

  static parse( dadosPessoa ) {
    const { telefone='', cpf='', nome: nomeSemTratamento='', email: emailSemTratamento='', erros=[], ...dados } = dadosPessoa
    const _cpf = sanitizeValue( cpf ).replace( /\D/g, '' ).substring( 0, 11 )
    const _telefone = sanitizeValue( telefone ).replace( /\D/g, '' ).substring( 0, 11 )
    const nome = TitleClass.transform( nomeSemTratamento )
    const email = emailSemTratamento.toLowerCase().replace( ' ', '' )
    return this.upsert( { _telefone, _cpf, nome, email, erros, ...dados } )
  }

  get camposObrigatorios() {
    return {
      [ Pessoa.PRESENTEADOR ]: [ `nome`, `email`, `cpf` ],
      [ Pessoa.PRESENTEADO ]: [ `nome` ],
      [ Pessoa.PRESENTEADO_RESGATE ]: [ `nome`, `email`, `cpf`, `telefone` ],
    }[ this.tipo ]
  }

  get cleanCPF() {
    return this._cpf
  }

  get cpf() {
    if ( empty( this._cpf ) ) {
      return
    } else if ( this._cpf.length <= 3 ) {
      return this._cpf
    } else if ( this._cpf.length <= 6 ) {
      return `${this._cpf.substring( 0, 3 )}.${this._cpf.substring( 3 )}`
    } else if ( this._cpf.length <= 9 ) {
      return `${this._cpf.substring( 0, 3 )}.${this._cpf.substring( 3, 6 )}.${this._cpf.substring( 6 )}`
    } else {
      return `${this._cpf.substring( 0, 3 )}.${this._cpf.substring( 3, 6 )}.${this._cpf.substring( 6, 9 )}-${this._cpf.substring( 9 )}`
    }
  }

  get telefone() {
    if ( empty( this._telefone ) ) {
      return
    } else if ( this._telefone.length <= 2 ) {
      return `(${this._telefone}`
    } else if ( this._telefone.length <= 7 ) {
      return `(${this._telefone.substring( 0, 2 )}) ${this._telefone.substring( 2 )}`
    } else {
      return `(${this._telefone.substring( 0, 2 )}) ${this._telefone.substring( 2, 7 )}.${this._telefone.substring( 7 )}`
    }
  }

  getErro( campo ) {
    const erro = this.erros.find( erro => erro.campo === campo )
    return erro ? erro.mensagem : ``
  }

  getErros( erros ) {
    if ( erros.every( e1 => this.erros.find( e2 => e1.campo === e2.campo ) ) ) {
    // Se todos os campos dos novos erros já existem nos erros da pessoa, basta substituir
      return this.erros.map( erro => erros.find( e => e.campo === erro.campo ) || erro )
    } else {
    // Se existe algum novo campo de erro nos novos erros, precisamos juntar os dois arrays
      return [ ...erros, ...this.erros ].reduce(
        ( acc, erro ) => {
          return acc.find( e => e.campo === erro.campo ) ?
            acc.map( e => e.campo === erro.campo ? erro : e ) :
            acc.concat( erro )
        }, [] )
    }
  }

  validarCampo( campo ) {
    let mensagem = ``
    if ( this.camposObrigatorios.includes( campo ) && empty( this[ campo ] ) ) {
      mensagem = `Campo obrigatório.`
    } else if ( campo === `cpf` && this._cpf.length < 11 ) {
      mensagem = `CPF deve conter 11 dígitos.`
    } else if ( campo === `cpf` && !validarCPF( this[ campo ] ) ) {
      mensagem = `CPF inválido.`
    } else if ( campo === `email` && !isValidEmail( this[ campo ] ) ) {
      mensagem = `Email inválido.`
    } else if ( campo === `telefone` && this._telefone.length < 11 ) {
      mensagem = `Celular deve conter 11 dígitos.`
    } else if ( campo === `telefone` && !validateTelephoneNumber( this._telefone ) ) {
      mensagem = `Celular inválido.`
    } else if ( [ Pessoa.PRESENTEADOR, Pessoa.PRESENTEADO_RESGATE ].includes( this.tipo ) ) {
      if ( campo === `nome` && this.nome.split( ' ' ).length < 2 ) {
        mensagem = `Por favor, preencha com nome e sobrenome.`
      }
    }
    return { campo, mensagem }
  }

  validar() {
    const erros = []
    for ( let campo of this.camposObrigatorios ) {
      if ( empty( this[ campo ] ) ) {
        erros.push( { campo, mensagem: `Campo obrigatório.` } )
      }
    }
    if ( this._cpf ) {
      if ( this._cpf.length < 11 ) {
        erros.push( { campo: `cpf`, mensagem: `CPF deve conter 11 dígitos.` } )
      } else if ( !validarCPF( this._cpf ) ) {
        erros.push( { campo: `cpf`, mensagem: `CPF inválido.` } )
      }
    }
    if ( this.email && !isValidEmail( this.email ) ) {
      erros.push( { campo: `email`, mensagem: `Email inválido.` } )
    }
    if ( this._telefone ) {
      if ( this._telefone.length < 11 ) {
        erros.push( { campo: `telefone`, mensagem: `Celular deve conter 11 dígitos.` } )
      } else if ( !validateTelephoneNumber( this._telefone ) ) {
        erros.push( { campo: `telefone`, mensagem: `Celular inválido.` } )
      }
    }
    return erros
  }

  getRef() {
    const { _telefone: telefone, _cpf: cpf, ...ref } = this.ref
    return { telefone, cpf, ...ref }
  }

  toJSON() {
    const {
      idOEF: id,
      _telefone: telefone,
      _cpf: cpf,
      nome,
      email,
      genero: sexo,
      senha,
    } = this.ref
    return {
      id: !empty( id ) ? id : undefined,
      telefone: !empty( telefone ) ? telefone : undefined,
      cpf: !empty( cpf ) ? cpf : undefined,
      nome: !empty( nome ) ? nome : undefined,
      email: !empty( email ) ? email : undefined,
      sexo: !empty( sexo ) && !isEmptyObject( sexo ) ? sexo.codigo : undefined,
      senha: !empty( senha ) ? senha : undefined,
    }
  }
}

Pessoa.modelName = `Pessoa`
