export function scrollTo( x, y ) {
  /**
    Como estamos usando overflow-y, precisamos pegar o pai desse elemento e não o window
  **/
  let body = document.querySelector( '.screen-container__body' )

  if ( body && body.scrollTo ) {
    body.scrollTo( x, y )
  } else {
    window.scrollTo( x, y )
  }
}

export function scrollTop() {
  scrollTo( 0, 0 )
}

/*
  O chrome renderiza tão rápido que o evento scrollTo é disparado antes do scroll padrão.
  Em outros browser, sem o time out, ele funciona normal
  https://stackoverflow.com/questions/15691569/javascript-issue-with-scrollto-in-chrome
  https://stackoverflow.com/questions/15260184/strange-behaviour-of-chrome-at-window-scrollto
*/
export const goToTop = () => setTimeout( () => scrollTop(), 0.1 )
