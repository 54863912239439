import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router, Route, browserHistory } from 'react-router'
import { Awin } from './shared/helpers/Awin'
import ResgateRoutes from './routes/resgate'

import configurarStore from './store/configurar'
const store = configurarStore()

const rootEl = document.getElementById( `root` )

require( `./shared/mixinsScss/assinaturaPresente.scss` )

Awin()

const render = () => {
  const App = require( `./layout/App` ).default

  ReactDOM.render(
    <Provider store={ store }>
      <Router
        history={ browserHistory }
      >
        <Route
          component={ App }
        >
          { ResgateRoutes() }
        </Route>
      </Router>
    </Provider>,
    rootEl
  )
}

render()
