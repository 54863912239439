import { createConditionalSliceReducer } from '../../common/utils/reducerUtils'
import orm from '../../schema/schema'

const updateCartaoCredito = ( state, payload ) => {
  const sessao = orm.session( state )
  const { CartaoCredito } = sessao

  CartaoCredito.parse( payload )
  return sessao.state
}

const receiveErrosCartaoCredito = ( state, payload ) => {
  const sessao = orm.session( state )
  const { CartaoCredito } = sessao

  const cartaoCredito = CartaoCredito.all().first()

  const errosBackend = payload.errors.map( ( { source: campo, message: mensagem } ) => ( { campo, mensagem } ) )
  const erros = cartaoCredito.getErros( errosBackend )

  CartaoCredito.parse( { ...cartaoCredito.getRef(), erros } )

  return sessao.state
}

export default createConditionalSliceReducer( 'entities', {
  UPDATE_CARTAO_CREDITO: updateCartaoCredito,
  RECEIVE_ERROS_CARTAO_CREDITO: receiveErrosCartaoCredito,
} )
