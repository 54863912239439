import Fetch from '../utils/fetch'

const { REACT_APP_API_BASE_URL } = process.env

export const consultaCep = ( { cep, dispatch, categoria } ) => {
  let fetch = new Fetch( { dispatch } )
  if ( cep.lenght < 8 ) {
    return
  }
  return fetch.get( { url: `${REACT_APP_API_BASE_URL}/v2/endereco/consulta-cep/${cep}/${categoria}` } )
}
