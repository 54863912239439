const VALID_EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\']+(\.[^<>()[\]\\.,;:\s@\']+)*)|(\'.+\'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const VALID_NOME_REGEX = /^[_A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ'-.\s]+$/
const MENSAGEM_CNPJ_INVALIDO = 'CNPJ inválido'
const MENSAGEM_EMAIL_INVALIDO = 'E-mail inválido'
const MENSAGEM_DATA_NASCIMENTO_INVALIDO = 'Data de nascimento inválida'
const MENSAGEM_FORMATO_DATA_NASCIMENTO_INVALIDO = 'Formato de data de nascimento inválido'
const MENSAGEM_TELEFONE_INVALIDO = 'Telefone inválido'
const MENSAGEM_CARTAO_INVALIDO = 'Cartão inválido'
const MENSAGEM_CVV_INVALIDO = 'CVV inválido'
const MENSAGEM_SENHA_INVALIDA = 'Senha muito fraca'
const MENSAGEM_SENHA_INCORRETA = 'Confirmação inválida'
const INCORRECT_PWD = MENSAGEM_SENHA_INCORRETA
const MENSAGEM_CAMPO_OBRIGATORIO = 'Campo obrigatório'
const TEXT = 'text'
const PASSWORD = 'password'
const EMAIL = 'email'

let empty = ( data ) => {
  if ( typeof ( data ) == 'number' || typeof ( data ) == 'boolean' ) {
    return false
  }
  if ( typeof ( data ) == 'undefined' || data === null ) {
    return true
  }
  if ( typeof ( data.length ) !== 'undefined' ) {
    return data.length == 0
  }
  if ( Object.prototype.toString.call( data ) === '[object Date]' ) {
    return isNaN( data.getTime() )
  }

  for ( let i in data ) {
    if ( data.hasOwnProperty( i ) ) {
      return false
    }
  }

  return true
}

let isEmptyObject = ( obj ) => {
  if ( !obj ) {
    return true
  }
  for ( let field in obj ) {
    if ( obj.hasOwnProperty( field ) ) {
      if ( !empty( obj[ field ] ) ) {
        return false
      }
    }
  }
  return true
}

let clearEmptyFields = ( obj ) => {
  let ret = {}
  for ( let field of Object.keys( obj ) ) {
    if ( !empty( obj[ field ] ) ) {
      ret = Object.assign( ret, { [ field ]: obj[ field ] } )
    }
  }
  return ret
}

let arrayEquals = ( arr1, arr2 ) => {
  for ( let el of arr1 ) {
    if ( arr2.indexOf( el ) === -1 ) {
      return false
    }
  }
  for ( let el of arr2 ) {
    if ( arr1.indexOf( el ) === -1 ) {
      return false
    }
  }
  return true
}

let objectEquals = ( obj1, obj2 ) => {
  obj1 = clearEmptyFields( obj1 )
  obj2 = clearEmptyFields( obj2 )
  if ( !arrayEquals( Object.keys( obj1 ), Object.keys( obj2 ) ) ) {
    return false
  }
  for ( let field of Object.keys( obj1 ) ) {
    if ( obj1[ field ] !== obj2[ field ] ) {
      return false
    }
  }
  return true
}

let sanitizeValue = ( inputValue ) => {
  if ( empty( inputValue ) || typeof ( inputValue.length ) === 'undefined' ) {
    return ''
  }
  // remover os seguintes caracteres especiais: '.', '-', '(', ')', '-', ' ', '/', '\', '*'
  const regex = /[.\-*/()_\s]/g
  return inputValue.replace( regex, '' )
}

const validateCNPJ = ( field ) => {
  let err = {}

  if ( empty( field.value ) ) {
    err.cnpj = MENSAGEM_CAMPO_OBRIGATORIO
    return err
  }

  let isValid = ( cnpj ) => {
    let invalidCNPJ = [ '00000000000000', '11111111111111', '22222222222222', '33333333333333', '44444444444444', '55555555555555', '66666666666666', '77777777777777', '88888888888888', '99999999999999' ]
    if ( invalidCNPJ.indexOf( cnpj ) !== -1 ) {
      return false
    }

    let tamanho
    let numeros
    let digitos
    let soma
    let pos
    let resultado

    tamanho = cnpj.length - 2
    numeros = cnpj.substring( 0, tamanho )
    digitos = cnpj.substring( tamanho )
    soma = 0
    pos = tamanho - 7
    for ( let i = tamanho; i >= 1; i-- ) {
      soma += numeros.charAt( tamanho - i ) * pos--
      if ( pos < 2 ) {
        pos = 9
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if ( resultado != digitos.charAt( 0 ) ) {
      return false
    }

    tamanho = tamanho + 1
    numeros = cnpj.substring( 0, tamanho )
    soma = 0
    pos = tamanho - 7
    for ( let i = tamanho; i >= 1; i-- ) {
      soma += numeros.charAt( tamanho - i ) * pos--
      if ( pos < 2 ) {
        pos = 9
      }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if ( resultado != digitos.charAt( 1 ) ) {
      return false
    }
    return true
  }

  let cnpj = sanitizeValue( field.value )
  err.cnpj = !isValid( cnpj ) ? MENSAGEM_CNPJ_INVALIDO : ''
  return err
}

let isValidEmail = ( email ) => {
  return empty( email ) || VALID_EMAIL_REGEX.test( email )
}

const validateEmail = ( field ) => {
  let err = {}

  if ( empty( field.value ) ) {
    err[ field.name ] = MENSAGEM_CAMPO_OBRIGATORIO
  } else if ( !isValidEmail( field.value ) ) {
    err[ field.name ] = MENSAGEM_EMAIL_INVALIDO
  } else {
    err[ field.name ] = ''
  }

  return err
}

let isValidNome = ( nome ) => {
  return empty( nome ) || VALID_NOME_REGEX.test( nome )
}

const requiredTelefone = ( field ) => {
  if ( !( field && field.value && field.value.length !== 0 ) ) {
    return { [ field.name ]: MENSAGEM_CAMPO_OBRIGATORIO }
  } else {
    return {
      [ field.name ]: validateTelephoneNumber( field.value ) ? '' : MENSAGEM_TELEFONE_INVALIDO
    }
  }
}

let validateTelephoneNumber = ( tel ) => {
  if ( !( tel && tel.length && typeof ( tel.length ) !== 'undefined' ) ) {
    return false
  }

  let areaCode = parseInt( tel.substring( 0, 2 ), 10 )
  if ( areaCode < 10 || areaCode % 10 === 0 ) {
    return false
  }

  if ( tel.length === 10 || tel.length === 11 ) {
    return true
  } else {
    return false
  }
}

const validateNumeroCartao = ( field ) => {
  let err = {}
  let numero = sanitizeValue( field.value )
  let minLength = numero.length >= 14
  let maxLength = numero.length <= 19

  if ( numero === '' ) {
    err[ field.name ] = MENSAGEM_CAMPO_OBRIGATORIO
  } else {
    err[ field.name ] = !( minLength && maxLength ) ? MENSAGEM_CARTAO_INVALIDO : ''
  }
  return err
}

const validateCVV = ( field ) => {
  let err = {}
  let cvv = sanitizeValue( field.value )

  if ( cvv.length === 0 ) {
    err = { cvv: MENSAGEM_CAMPO_OBRIGATORIO }
  } else if ( cvv.length !== 3 && cvv.length !== 4 ) {
    err = { cvv: MENSAGEM_CVV_INVALIDO }
  } else {
    err = { cvv: '' }
  }
  return err
}

const validateDtNascimento = ( field ) => {
  let err = {}

  if ( !( field && field.value && field.value.length !== 0 ) ) {
    err = { dt_nascimento: MENSAGEM_CAMPO_OBRIGATORIO }
  } else if ( [ 'number', 'boolean' ].includes( typeof field.value ) || !field.value.includes( '/' ) ) {
    err = { dt_nascimento: MENSAGEM_FORMATO_DATA_NASCIMENTO_INVALIDO }
  } else {
    let parsedValue = field.value.split( '/' )
    let dia = parseInt( parsedValue[ 0 ], 10 )
    let mes = parseInt( parsedValue[ 1 ], 10 )

    if ( dia === 0 || dia > 31 ) {
      err = { dt_nascimento: MENSAGEM_DATA_NASCIMENTO_INVALIDO }
    } else if ( mes === 0 || mes > 12 ) {
      err = { dt_nascimento: MENSAGEM_DATA_NASCIMENTO_INVALIDO }
    } else if ( [ 4, 6, 9, 11 ].includes( mes ) && dia > 30 ) {
      err = { dt_nascimento: MENSAGEM_DATA_NASCIMENTO_INVALIDO }
    } else if ( mes === 2 && dia > 29 ) {
      err = { dt_nascimento: MENSAGEM_DATA_NASCIMENTO_INVALIDO }
    } else {
      let dt_nascimento = new Date( parsedValue )

      if ( dt_nascimento > new Date() ) {
        err = { dt_nascimento: MENSAGEM_DATA_NASCIMENTO_INVALIDO }
      } else {
        err = { dt_nascimento: '' }
      }
    }
  }
  return err
}

const validatePwd = ( pwd ) => {
  if ( !pwd.password || pwd.password.length === 0 ) {
    return { pwd: MENSAGEM_CAMPO_OBRIGATORIO }
  }
  if ( pwd.password && !pwd.isValid ) {
    return { pwd: MENSAGEM_SENHA_INVALIDA }
  }
  return { pwd: '' }
}

const validatePwdCopy = ( pwd, pwdCopy ) => {
  if ( pwd.password !== pwdCopy ) {
    return { pwdCopy: MENSAGEM_SENHA_INCORRETA }
  }
  return { pwdCopy: '' }
}

const requiredField = ( field ) => {
  let err = {}
  err[ field.name ] = empty( field.value ) ? MENSAGEM_CAMPO_OBRIGATORIO : ''
  return err
}

const CPF_INVALIDO = 'CPF inválido'

const validateCPF = ( field ) => {
  let err = {}

  if ( empty( field.value ) ) {
    err.cpf = MENSAGEM_CAMPO_OBRIGATORIO
    return err
  }

  let cpf = sanitizeValue( field.value )

  err.cpf = !validarCPF( cpf ) ? CPF_INVALIDO : ''
  return err
}

let validateTelefone = ( field ) => {
  let err = {}
  if ( field.value.length === 0 ) {
    err = { [ field.name ]: '' }
  } else if ( field.value.length < 10 || field.value.length > 11 ) {
    err = { [ field.name ]: MENSAGEM_TELEFONE_INVALIDO }
  } else {
    err = { [ field.name ]: '' }
  }
  return err
}

const validarCPF = cpf => {
  let isValid = ( strCPF ) => {
    let soma = 0
    let resto
    let invalidCPF = [ '00000000000', '11111111111', '22222222222', '33333333333', '44444444444', '55555555555', '66666666666', '77777777777', '88888888888', '99999999999' ]
    if ( invalidCPF.indexOf( strCPF ) !== -1 ) {
      return false
    }

    for ( let i = 1; i <= 9; i++ ) {
      soma = soma + parseInt( strCPF.substring( i - 1, i ) ) * ( 11 - i )
    }

    resto = ( soma * 10 ) % 11
    if ( resto == 10 || resto == 11 ) {
      resto = 0
    }

    if ( resto !== parseInt( strCPF.substring( 9, 10 ) ) ) {
      return false
    }

    soma = 0
    for ( let i = 1; i <= 10; i++ ) {
      soma = soma + parseInt( strCPF.substring( i - 1, i ) ) * ( 12 - i )
    }

    resto = ( soma * 10 ) % 11
    if ( resto == 10 || resto == 11 ) {
      resto = 0
    }

    if ( resto !== parseInt( strCPF.substring( 10, 11 ) ) ) {
      return false
    }

    return true
  }
  return isValid( sanitizeValue( cpf ) )
}

export {
  MENSAGEM_EMAIL_INVALIDO,
  MENSAGEM_DATA_NASCIMENTO_INVALIDO,
  MENSAGEM_TELEFONE_INVALIDO,
  MENSAGEM_CVV_INVALIDO,
  INCORRECT_PWD,
  MENSAGEM_CAMPO_OBRIGATORIO,
  TEXT,
  PASSWORD,
  EMAIL,
  empty,
  isEmptyObject,
  clearEmptyFields,
  arrayEquals,
  objectEquals,
  sanitizeValue,
  isValidEmail,
  isValidNome,
  validateTelephoneNumber,
  CPF_INVALIDO,
  validateTelefone,
  validarCPF,
  validateCNPJ,
  validateEmail,
  requiredTelefone,
  validateNumeroCartao,
  validateCVV,
  validateDtNascimento,
  validatePwd,
  validatePwdCopy,
  requiredField,
  validateCPF
}
