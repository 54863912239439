import { createReducer } from '../../common/utils/reducerUtils'

import {
  URL_CAIXINHA,
  URL_IDENTIFICACAO,
  URL_ENTREGA,
  URL_PAGAMENTO,
} from './constants'

const initialState = {
  passos: [
    {
      codigo: 1,
      completo: false,
      url: URL_CAIXINHA,
      visualizacao: `Caixinha`
    }, {
      codigo: 2,
      completo: false,
      url: URL_IDENTIFICACAO,
      visualizacao: `Identificação`
    }, {
      codigo: 3,
      completo: false,
      url: URL_ENTREGA,
      visualizacao: `Entrega`
    }, {
      codigo: 4,
      completo: false,
      url: URL_PAGAMENTO,
      visualizacao: `Pagamento`
    }
  ],
  passoAtivo: 1,
  emailPDFEnviado: false
}

const setarPassosNaoCompletos = ( state, ativo ) => {
  const passos = state.passos.map( passo => {
    if ( passo.codigo >= ativo ) {
      return { ...passo, completo: false }
    } else {
      return passo
    }
  } )
  return passos
}

const completarPasso = ( state, passo ) => {
  const passos = state.passos.map( p => {
    if ( p.codigo === passo ) {
      return { ...p, completo: true }
    } else {
      return p
    }
  } )
  return passos
}

const voltar = state => {
  const { passoAtivo: passo } = state
  if ( passo === 1 ) {
    return state
  } else {
    const passoAtivo = passo - 1
    const passos = setarPassosNaoCompletos( state, passoAtivo )
    return { ...state, passos, passoAtivo }
  }
}

const completarPassoCaixinha = state => {
  const passos = completarPasso( state, 1 )
  return { ...state, passos, passoAtivo: 2 }
}

const completarPassoIdentificacao = state => {
  const passos = completarPasso( state, 2 )
  return { ...state, passos, passoAtivo: 3 }
}

const completarPassoEntrega = state => {
  const passos = completarPasso( state, 3 )
  return { ...state, passos, passoAtivo: 4 }
}

const completarCompraAssinaturaPresente = state => {
  const passos = completarPasso( state, 4 )
  return { ...state, passos, passoAtivo: 5 }
}

const selecionarPasso = ( state, payload ) => {
  const { codigo } = payload
  const passos = setarPassosNaoCompletos( state, codigo )
  return { ...state, passos, passoAtivo: codigo }
}

const emailPDFEnviado = state => ( { ...state, emailPDFEnviado: true } )

const resetEmailPDF = state => ( { ...state, emailPDFEnviado: false } )

export default createReducer( initialState, {
  VOLTAR: voltar,
  COMPLETAR_PASSO_CAIXINHA: completarPassoCaixinha,
  COMPLETAR_PASSO_IDENTIFICACAO: completarPassoIdentificacao,
  COMPLETAR_PASSO_ENTREGA: completarPassoEntrega,
  COMPLETAR_COMPRA_ASSINATURA_PRESENTE: completarCompraAssinaturaPresente,
  SELECIONAR_PASSO: selecionarPasso,
  EMAIL_PDF_ENVIADO: emailPDFEnviado,
  RESET_EMAIL_PDF: resetEmailPDF,
} )
