import { getSessaoEntidades } from '../entidades/selectors'
import { updateCartaoCredito, receiveErrosCartaoCredito } from '../cartao-credito/actions'
import API from '../../data/API'
import URLHelper from '../../shared/helpers/URLHelper'
import { normalizeString } from '../../shared/utils/formatters'
import { gtmTransactionEvent } from '../marketing/actions'

import {
  URL_CAIXINHA,
  URL_IDENTIFICACAO,
  URL_ENTREGA,
  URL_PAGAMENTO,
  URL_CONCLUIDO,
  URL_RESGATE,
  URL_RESGATE_CONCLUIDO,
} from './constants'

export const completarPassoCaixinha = payload => {
  return ( dispatch, getState ) => {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Categoria, Plano } = sessao

    const assinaturaPresente = AssinaturaPresente.all().first()
    const categoria = Categoria.get( { id: payload.categoria } )
    const plano = Plano.get( { categoria: categoria.id, totalKits: assinaturaPresente.plano.totalKits } )

    URLHelper.goTo( URL_IDENTIFICACAO( normalizeString( categoria.nome ) ) )

    dispatch( {
      type: `COMPLETAR_PASSO_CAIXINHA`,
      payload: {
        categoria,
        plano
      }
    } )
  }
}

export const voltar = () => {
  return {
    type: `VOLTAR`
  }
}

export const selecionarPasso = payload => {
  return function( dispatch, getState ) {
    const { fluxo: { passos } } = getState()
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente } = sessao

    const { categoria, tipo } = AssinaturaPresente.all().first()

    const passo = passos.find( passo => passo.codigo === payload.codigo )

    let url
    switch ( passo.codigo ) {
      case 0:
      case 1:
        url = passo.url
        break
      case 2:
        url = passo.url( normalizeString( categoria.nome ) )
        break
      case 3:
      case 4:
        url = passo.url( normalizeString( categoria.nome ), normalizeString( tipo.visualizacao ) )
        break
    }
    URLHelper.goTo( url )

    dispatch( {
      type: `SELECIONAR_PASSO`,
      payload
    } )
  }
}

export const completarPassoIdentificacao = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Etiqueta, Pessoa } = sessao

    const etiqueta = Etiqueta.all().first()
    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO } )
    const presenteador = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )

    const payload = await API.salvarDadosPessoais( {
      etiqueta: etiqueta.isEmpty ? undefined : etiqueta.toJSON(),
      presenteado: presenteado.toJSON(),
      presenteador: presenteador.toJSON(),
    } )
    dispatch( {
      type: `COMPLETAR_PASSO_IDENTIFICACAO`,
      payload
    } )
    const { categoria, tipo } = AssinaturaPresente.all().first()
    URLHelper.goTo( URL_ENTREGA( normalizeString( categoria.nome ), normalizeString( tipo.visualizacao ).replace( ' ', '-' ) ) )
  }
}

export const completarPassoEntrega = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Endereco, Pessoa } = sessao

    const endereco = Endereco.all().first()
    const presenteador = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )
    const payload = await API.salvarDadosEndereco( {
      endereco: endereco.toJSON(),
      usuario: presenteador.idOEF,
    } )
    dispatch( {
      type: `COMPLETAR_PASSO_ENTREGA`,
      payload
    } )
    const { categoria, tipo } = AssinaturaPresente.all().first()
    URLHelper.goTo( URL_PAGAMENTO( normalizeString( categoria.nome ), normalizeString( tipo.visualizacao ).replace( ' ', '-' ) ) )
  }
}

const getPayloadCartaoCredito = async function( dispatch, sessao ) {
  const { CartaoCredito } = sessao

  const cartaoCredito = CartaoCredito.all().first()

  let cartaoTokenizado
  try {
    cartaoTokenizado = await cartaoCredito.tokenizar()
  } catch ( erros ) {
    dispatch( updateCartaoCredito( { ...cartaoCredito.toJSON(), erros } ) )
    return
  }

  const {
    token,
    nome,
    mesVencimento,
    anoVencimento,
    ultimosDigitos,
    bandeira,
  } = cartaoTokenizado

  const payload = {
    token,
    nome,
    mes_validade: mesVencimento,
    ano_validade: anoVencimento,
    ultimos_digitos: ultimosDigitos,
    bandeira
  }

  dispatch( updateCartaoCredito( { ...cartaoCredito.toJSON(), token, nome, vencimento: `${mesVencimento}/${anoVencimento}`, ultimosDigitos, bandeira } ) )

  return payload
}

export const completarPassoPagamento = captcha => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Endereco, Pessoa } = sessao

    const assinaturaPresente = AssinaturaPresente.all().first()
    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO } )
    const presenteador = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )
    const endereco = Endereco.all().first()

    const categoria = { id: assinaturaPresente.categoria.id }
    const dadosPessoais = {
      presenteado: { id: presenteado.idOEF },
      presenteador: { id: presenteador.idOEF }
    }
    if ( !assinaturaPresente.etiqueta.isEmpty ) {
      dadosPessoais.etiqueta = assinaturaPresente.etiqueta.toJSON()
    }
    const entrega = { id: endereco.idOEF }
    const pagamento = {
      forma: assinaturaPresente.formaPagamento.codigo,
      plano: { id: assinaturaPresente.plano.id },
    }

    if ( assinaturaPresente.formaPagamento.codigo === AssinaturaPresente.CARTAO_CREDITO.codigo ) {
      pagamento.cartao_credito = await getPayloadCartaoCredito( dispatch, sessao )
      pagamento.parcelas = assinaturaPresente.parcelas
    }

    const payload = {
      tipo_presente: assinaturaPresente.tipo.codigo,
      categoria,
      dados_pessoais: dadosPessoais,
      endereco: entrega,
      pagamento,
      captcha,
    }

    let resposta
    try {
      resposta = await API.comprar( payload )
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosCartaoCredito( erros ) )
      return
    }
    dispatch( completarCompraAssinaturaPresente( resposta ) )
    dispatch( gtmTransactionEvent() )

    const { categoria: cat, tipo } = AssinaturaPresente.all().first()
    URLHelper.goTo( URL_CONCLUIDO( normalizeString( cat.nome ), normalizeString( tipo.visualizacao ).replace( ' ', '-' ) ) )
  }
}

export const completarCompraAssinaturaPresente = ( payload ) => {
  return {
    type: `COMPLETAR_COMPRA_ASSINATURA_PRESENTE`,
    payload
  }
}

export const completarResgate = () => {
  URLHelper.goTo( URL_RESGATE_CONCLUIDO )
  return {
    type: `COMPLETAR_RESGATE`
  }
}

export const redirecionarParaPassoInicialCompra = () => {
  URLHelper.goTo( URL_CAIXINHA )
  return {
    type: `REDIRECIONAR_PARA_PASSO_INICIAL_COMPRA`
  }
}

export const redirecionarParaPassoInicialResgate = () => {
  URLHelper.goTo( URL_RESGATE )
  return {
    type: `REDIRECIONAR_PARA_PASSO_INICIAL_RESGATE`
  }
}

export const resetEmailPDF = () => {
  return {
    type: `RESET_EMAIL_PDF`
  }
}
