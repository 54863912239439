import { Model, attr, fk } from 'redux-orm'
import { CARTAO_VIRTUAL, CAIXINHA } from './constants'

export default class AssinaturaPresente extends Model {
  static CARTAO_CREDITO = { visualizacao: `Cartão de crédito`, codigo: `C` }
  static BOLETO = { visualizacao: `Boleto bancário`, codigo: `B` }
  static FORMAS_PAGAMENTO = [ AssinaturaPresente.CARTAO_CREDITO, AssinaturaPresente.BOLETO ]

  static CARTAO_VIRTUAL = { visualizacao: `Cartão virtual`, codigo: CARTAO_VIRTUAL }
  static CAIXINHA = { visualizacao: `Caixinha`, codigo: CAIXINHA }
  static TIPOS = [ AssinaturaPresente.CARTAO_VIRTUAL, AssinaturaPresente.CAIXINHA ]

  static get fields() {
    return {
      categoria: fk( `Categoria` ),
      etiqueta: fk( `Etiqueta` ),
      formaPagamento: attr(),
      parcelas: attr(),
      plano: fk( `Plano` ),
      tipo: attr(),
      codigo: attr(),
      pdf: attr(),
      boleto: attr(),
      vendas: attr(),
    }
  }

  static parse( dadosAssinaturaPresente ) {
    return this.upsert( dadosAssinaturaPresente )
  }

  isCartaoCredito() {
    return this.formaPagamento.codigo === AssinaturaPresente.CARTAO_CREDITO.codigo
  }

  isBoleto() {
    return this.formaPagamento.codigo === AssinaturaPresente.BOLETO.codigo
  }

  isCaixinha() {
    return this.tipo.codigo === AssinaturaPresente.CAIXINHA.codigo
  }

  isCartaoVirtual() {
    return this.tipo.codigo === AssinaturaPresente.CARTAO_VIRTUAL.codigo
  }

  toJSON() {
    return { ...this.ref }
  }
}

AssinaturaPresente.modelName = `AssinaturaPresente`
