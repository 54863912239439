import API from '../../data/API'

export function loadCategorias() {
  return async function( dispatch ) {
    const payload = await API.buscarCategorias()
    dispatch( {
      type: 'CARREGAR_CATEGORIAS',
      payload
    } )
  }
}
