import { Model, attr, fk } from 'redux-orm'

export default class Plano extends Model {
  static PARCELAS = [ 1, 3, 6, 12 ]
  static get fields() {
    return {
      id: attr(),
      tipo: attr(),
      totalKits: attr(),
      valorPorKit: attr(),
      valorTotal: attr(),
      categoria: fk( `Categoria` ),
      formasPagamento: attr(),
      parcelas: attr(),
    }
  }

  static parse( dadosPlano ) {
    const {
      total_kits: totalKits,
      valor_total: valorTotal,
      formas_pagamento: formasPagamento,
      tipo_plano: tipo,
      valor_por_kit: valorPorKit,
      categoria,
      id,
    } = dadosPlano
    return this.upsert( {
      totalKits,
      valorTotal,
      formasPagamento,
      tipo,
      valorPorKit,
      categoria,
      id,
      parcelas: Plano.PARCELAS.filter( parcela => parcela <= totalKits )
    } )
  }
}

Plano.modelName = `Plano`
