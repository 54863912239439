import React, { Fragment } from 'react'
import { bool, func, object, string } from 'prop-types'
import styled from 'styled-components'

import { Card, Textbox, StyleGuide } from 'oef_componentes'
import Logo from './Logo'

const CodigoResgateWrapper = styled.div `
  .card--sidebar__resgate {
    @media (max-width: ${StyleGuide.mediaQuery.tablet}) {
      box-shadow: none;
      padding: 0;
    }

    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      background-color: transparent;
    }
  }

  .titulo--sidebar__resgate {
    align-items: center;
    color: ${StyleGuide.cores.cinza500};
    display: flex;
    font-size: 18px;
    ${StyleGuide.boldFonte}
    margin-bottom: 30px;
  }

  .subtitulo--sidebar__resgate {
    ${StyleGuide.tipografia.textoHelper}
    color: ${StyleGuide.cores.cinza300};
    margin: 10px 0;
    text-transform: uppercase;
  }

  .info--sidebar__resgate {
    ${StyleGuide.tipografia.textoDefault}
    ${props => props.codigoValido ? `color: ${StyleGuide.cores.cinza500};` : `color: ${StyleGuide.cores.cinza300};`}
    margin-bottom: 20px;
  }

  .lista--sidebar__resgate {
    display: flex;
    margin-top: 75px;

    strong {
      color: ${StyleGuide.cores.cinza300};
      display: block;
      ${StyleGuide.boldFonte}
      font-size: 14px;
      margin-bottom: 15px;
      text-transform: uppercase;
    }

    li {
      color: ${StyleGuide.cores.cinza500};
      ${StyleGuide.defaultFonte}
      font-size: 18px;
      width: calc(100%/2);

      span {
        font-size: 27px;
      }
    }
  }

  .titulo--validado__resgate {
    color: ${StyleGuide.cores.azul};
    font-size: 20px;
    ${StyleGuide.boldFonte}
    margin: 10px 0;

    @media (min-width: ${StyleGuide.mediaQuery.tablet}) {
      font-size: 40px;
    }
  }

  .row--input {
    input {
      text-transform: uppercase;
    }
  }
`

const CodigoResgate = ( { assinaturaPresente, codigoValidado, codigoValido, concluido, erroCodigo, onBlurCodigo, onChangeCodigo, periodo, textoPeriodo } ) => {
  const infoSidebar = codigoValido ? (
    <Fragment>
      <h2 className={ `titulo--validado__resgate` }>{ `Parabéns!` }</h2>
      <p className={ `info--sidebar__resgate` }>
        { `Você ganhou ` }<strong>{ textoPeriodo }</strong>{ ` de assinatura, ou seja, ${periodo} caixinha${parseInt( periodo, 10 ) === 1 ? '' : 's'} na porta da sua casa!` }
      </p>
      <p className={ `info--sidebar__resgate` }>
        { `Você poderá conferir a chegada das suas caixinhas pela `}<strong>{ `área do associado` }</strong>{ `, além de acesso ao `}<strong>{ `aplicativo da TAG` }</strong>{ ` no seu celular e desconto na loja!` }
      </p>
    </Fragment>
  ) : (
    <p className={ `info--sidebar__resgate` }>{ `Digite no campo acima o código para saber seu presente` }</p>
  )

  return (
    <CodigoResgateWrapper codigoValido={ codigoValido }>
      <Card
        className={ `card--sidebar__resgate` }
      >
        <Fragment>
          <p className={ `titulo--sidebar__resgate` }>
            { !concluido ? <span className={ `numero--passo__resgate` }>{ `1` }</span> : undefined }
            { `Digite o código` }
          </p>
          <div className={ `row--input` }>
          <Textbox 
            buttonValue={ `Confirmar` }
            erro={ erroCodigo }
            label={ `Código` }
            name={ `codigo` }
            onBlur={ onBlurCodigo }
            onChange={ onChangeCodigo }
            sucesso={ codigoValidado && !concluido ? codigoValido : undefined }
            tipo={ `text` }
            value={ assinaturaPresente.codigo || '' }
        />
          </div>
          <p className={ `subtitulo--sidebar__resgate` }>{ `SEU PRESENTE` }</p>
          { infoSidebar }
          <ul className={ `lista--sidebar__resgate` }>
            <li>
              <strong>{ `Plano` }</strong>
              { codigoValido ? <Logo categoria={ assinaturaPresente.categoria } /> : '-' }
            </li>
            <li>
              <strong>{ `Período` }</strong>
              { codigoValido ? textoPeriodo : '-' }
            </li>
          </ul>
        </Fragment>
      </Card>
    </CodigoResgateWrapper>
  )
}

CodigoResgate.propTypes = {
  assinaturaPresente: object.isRequired,
  codigoValidado: bool.isRequired,
  codigoValido: bool.isRequired,
  concluido: bool,
  erroCodigo: string,
  onBlurCodigo: func,
  onChangeCodigo: func,
  periodo: string.isRequired,
  textoPeriodo: string.isRequired,
}

CodigoResgate.defaultProps = {
  concluido: false,
  erroCodigo: '',
  onBlurCodigo: () => {},
  onChangeCodigo: () => {},
}

export default CodigoResgate
