import { createReducer } from '../common/utils/reducerUtils'

const initialState = {
  codigoValidado: false,
  codigoValido: false,
  dadosCarregados: false,
  validandoCodigo: false,
}

const dadosCarregados = state => ( { ...state, dadosCarregados: true } )

const precisaValidar = state => ( { ...state, validandoCodigo: false, codigoValidado: false, codigoValido: false } )

const validandoCodigo = state => ( { ...state, validandoCodigo: true, codigoValidado: false } )

const receberCodigo = state => ( { ...state, validandoCodigo: false, codigoValidado: true, codigoValido: true } )

const receberCodigoInvalido = state => ( { ...state, validandoCodigo: false, codigoValidado: true, codigoValido: false } )

export default createReducer( initialState, {
  CARREGAR_CATEGORIAS: dadosCarregados,
  PRECISA_VALIDAR: precisaValidar,
  VALIDANDO_CODIGO: validandoCodigo,
  RECEBER_CODIGO: receberCodigo,
  RECEBER_CODIGO_INVALIDO: receberCodigoInvalido,
} )
