import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { bool, element, func, object } from 'prop-types'
import styled from 'styled-components'

import { goToTop } from '../shared/utils/scroll'

import { init } from '../funcionalidades/entidades/actions'
import { loadCategorias } from '../funcionalidades/categoria/actions'
import { voltar, redirecionarParaPassoInicialCompra, redirecionarParaPassoInicialResgate } from '../funcionalidades/fluxo/actions'
import { gtmPageViewSPAEvent } from '../funcionalidades/marketing/actions'

import Header from './Header'

import { StyleGuide } from 'oef_componentes'

const actions = {
  init,
  gtmPageViewSPAEvent,
  loadCategorias,
  redirecionarParaPassoInicialCompra,
  redirecionarParaPassoInicialResgate,
  voltar,
}

const Main = styled.main`
  margin: 0 auto;
  padding: 20px 0;
  width: 100%;

  @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
    min-height: calc(100vh - 110px);
    background-color: ${props => props.compra && StyleGuide.cores.cinza100};
  }

  @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
    max-width: 520px;
  }

  @media (min-width: ${StyleGuide.mediaQuery.tablet}) {
    max-width: 850px;
    padding: 40px 0;
  }
`

class App extends Component {
  static propTypes = {
    compra: bool,
    content: element.isRequired,
    dadosCarregados: bool.isRequired,
    gtmPageViewSPAEvent: func.isRequired,
    header: element,
    init: func.isRequired,
    loadCategorias: func.isRequired,
    location: object.isRequired,
    redirecionarParaPassoInicialCompra: func.isRequired,
    redirecionarParaPassoInicialResgate: func.isRequired,
    voltar: func.isRequired,
  }

  static defaultProps = {
    compra: false,
    header: undefined,
  }

  static ContentCarregado = ( { dadosCarregados, header, compra, content } ) => {
    if ( dadosCarregados ) {
      return (
        <Fragment>
          <header>
            <Header
              compra={ compra }
              imagePositionY="600"
              imageSize="250"
              textSubtitle="Agora é a hora de aproveitar seu presente! Preencha o campo abaixo com o código que você recebeu, e depois é só preencher os seus dados para finalizar."
              textTitle="Que presentão, hein!"
            >
              { header ? header : undefined }
            </Header>
          </header>
          <Main compra={ compra }>
            <section className={ `section__main` }>
              { content }
            </section>
          </Main>
        </Fragment>
      )
    } else {
      return null
    }
  }

  componentDidMount() {
    if ( window.location.pathname.includes( `/presente` ) ) {
      this.props.redirecionarParaPassoInicialCompra()
    } else if ( window.location.pathname.includes( `/meu-presente` ) ) {
      this.props.redirecionarParaPassoInicialResgate()
    }

    this.props.init()
    this.props.loadCategorias()
  }

  componentDidUpdate( prevProps ) {
    if ( prevProps.location !== this.props.location ) {
      if ( this.props.location.action === `POP` ) {
        this.props.voltar()
      }
      goToTop()
      this.props.gtmPageViewSPAEvent()
    }
  }

  render() {
    return (
      <App.ContentCarregado
        compra={ this.props.compra }
        content={ this.props.content }
        dadosCarregados={ this.props.dadosCarregados }
        header={ this.props.header }
      />
    )
  }
}

const mapStateToProps = store => {
  const { api } = store
  return {
    dadosCarregados: api.dadosCarregados,
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    actions
  )( App )
)
