import { createConditionalSliceReducer } from '../../common/utils/reducerUtils'
import orm from '../../schema/schema'


const updateEtiqueta = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Etiqueta } = sessao

  Etiqueta.parse( payload )

  return sessao.state
}

export default createConditionalSliceReducer( 'entities', {
  UPDATE_ETIQUETA: updateEtiqueta
} )
