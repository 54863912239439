import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, object } from 'prop-types'
import { Form, Input, StyleGuide } from 'oef_componentes'
import styled from 'styled-components'

import { getEnderecoPorCEP, updateEndereco } from './actions'
import { getArrayDeErros } from '../../common/utils/modelUtils'
import { empty } from '../../shared/utils/validators'
import { URL_CORREIOS } from '../../shared/constants'

const actions = {
  getEnderecoPorCEP,
  updateEndereco
}

const WrapperEnderecoForm = styled.div `
  margin-top: 20px;
  width: 100%;

  .link--cep {
    color: ${ StyleGuide.cores.azul };
    margin-left: 20px;
    text-decoration: underline;

    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      margin-bottom: 12px;
      max-width: 150px;
      width: 100%;
    }
  }

  .row--form {
    @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
      margin-bottom: 20px;
    }
  }

  .row--double {
    @media (min-width: ${StyleGuide.mediaQuery.mobile})  {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .row--cep {
    justify-content: flex-start;

    @media (max-width: ${StyleGuide.mediaQuery.mobile}) {
      align-items: center;
      display: flex;
    }
  }
`

class EnderecoForm extends Component {
  static propTypes = {
    endereco: object.isRequired,
    getEnderecoPorCEP: func.isRequired,
    updateEndereco: func.isRequired,
  }

  onBlurCEP = event => {
    if ( !empty( event.target.value ) ) {
      this.props.getEnderecoPorCEP( { cep: this.props.endereco.cleanCEP } )
    }
    this.onBlurEndereco( event )
  }

  onBlurEndereco = event => {
    const { name } = event.target
    const erro = this.props.endereco.validarCampo( name )
    const erros = this.props.endereco.erros.length > 0 ?
      getArrayDeErros( this.props.endereco.erros, erro ) :
      [ erro ]
    this.props.updateEndereco( { ...this.props.endereco.toRef(), erros } )
  }

  onChangeEndereco = event => {
    const { name: campo, value } = event.target
    const erro = { campo, mensagem: `` }
    const erros = this.props.endereco.erros.length > 0 ?
      getArrayDeErros( this.props.endereco.erros, erro ) :
      [ erro ]
    this.props.updateEndereco( { ...this.props.endereco.toRef(), erros, [ campo ]: value } )
  }

  getErro( campo ) {
    return this.props.endereco.getErro && this.props.endereco.getErro( campo )
  }

  render() {
    return (
      <WrapperEnderecoForm>
        <Form>
          <div className={ `row--form row--double row--cep` }>
            <Input
              column={ 2.5 }
              erro={ this.getErro( `cep` ) }
              label={ `CEP` }
              name={ `cep` }
              onBlur={ this.onBlurCEP.bind( this ) }
              onChange={ this.onChangeEndereco.bind( this ) }
              onPressEnter={ this.onBlurCEP.bind( this ) }
              tipo={ Input.TIPO_TEL }
              value={ this.props.endereco.cep || `` }
            />
            <a
              className={ `link--cep` }
              href={ URL_CORREIOS }
              target={ `_blank` }
            >
              { `Não sei meu CEP` }
            </a>
          </div>
          <div className={ `row--form row--rua` }>
            <Input
              erro={ this.getErro( `rua` ) }
              label={ `Rua` }
              name={ `rua` }
              onBlur={ this.onBlurEndereco.bind( this ) }
              onChange={ this.onChangeEndereco.bind( this ) }
              onPressEnter={ this.onBlurEndereco.bind( this ) }
              value={ this.props.endereco.rua || `` }
            />
          </div>
          <div className={ `row--form row--double row--numero` }>
            <Input
              column={ 3.5 }
              erro={ this.getErro( `numero` ) }
              label={ `Número` }
              name={ `numero` }
              onBlur={ this.onBlurEndereco.bind( this ) }
              onChange={ this.onChangeEndereco.bind( this ) }
              tipo={ Input.TIPO_TEL }
              value={ this.props.endereco.numero || `` }
            />
            <Input
              column={ 1.5 }
              erro={ this.getErro( `complemento` ) }
              label={ `Complemento` }
              name={ `complemento` }
              onBlur={ this.onBlurEndereco.bind( this ) }
              onChange={ this.onChangeEndereco.bind( this ) }
              value={ this.props.endereco.complemento || `` }
            />
          </div>
          <div className={ `row--form row--double` }>
            <Input
                erro={ this.getErro( `bairro` ) }
                label={ `Bairro` }
                name={ `bairro` }
                onBlur={ this.onBlurEndereco.bind( this ) }
                onChange={ this.onChangeEndereco.bind( this ) }
                onPressEnter={ this.onBlurEndereco.bind( this ) }
                value={ this.props.endereco.bairro || `` }
              />
          </div>
          <div className={ `row--form row--double row--uf` }>
            <Input
              column={ 3.5 }
              erro={ this.getErro( `uf` ) }
              label={ `UF` }
              name={ `uf` }
              onBlur={ this.onBlurEndereco.bind( this ) }
              onChange={ this.onChangeEndereco.bind( this ) }
              value={ this.props.endereco.uf || `` }
            />
            <Input
              column={ 1.5 }
              erro={ this.getErro( `cidade` ) }
              label={ `Cidade` }
              name={ `cidade` }
              onBlur={ this.onBlurEndereco.bind( this ) }
              onChange={ this.onChangeEndereco.bind( this ) }
              value={ this.props.endereco.cidade || `` }
            />
          </div>
        </Form>
      </WrapperEnderecoForm>
    )
  }
}

export default connect(
  null,
  actions
)( EnderecoForm )
