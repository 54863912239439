import React from 'react'
import { connect } from 'react-redux'
import { bool, func, oneOf, string } from 'prop-types'
import { Link } from 'react-router'

import { onClickButton } from '../../middleware/leadLogs'

export const TYPE_NORMAL = 'normal'
export const TYPE_EDIT = 'edit'
export const TYPE_CANCEL = 'cancel'
export const TYPE_ADD = 'add'
export const TYPE_SUSPEND = 'suspend'
export const TYPE_CONFIRM = 'confirm'
export const TYPE_KEEP = 'keep'
export const TYPE_SELECIONAR_CATEGORIA_INEDITOS = 'ineditos'
export const TYPE_SELECIONAR_CATEGORIA_CURADORIA = 'curadoria'
export const TYPE_CONFIRM_ICON = 'confirm--icon'
export const TYPE_CADEADO = 'cadeado'
export const TYPE_VOLTAR = 'voltar'
export const TYPE_CONTINUAR = 'continuar'
export const TYPE_TRANSPARENT = 'transparent'

const ICON_TYPES = [ TYPE_EDIT, TYPE_ADD, TYPE_CADEADO ]
const VALID_TYPES = [
  TYPE_ADD,
  TYPE_CADEADO,
  TYPE_CANCEL,
  TYPE_CONFIRM_ICON,
  TYPE_CONFIRM,
  TYPE_CONTINUAR,
  TYPE_EDIT,
  TYPE_KEEP,
  TYPE_NORMAL,
  TYPE_SELECIONAR_CATEGORIA_CURADORIA,
  TYPE_SELECIONAR_CATEGORIA_INEDITOS,
  TYPE_SUSPEND,
  TYPE_TRANSPARENT,
  TYPE_VOLTAR,
]
const ICON_SETA_TYPES = [ TYPE_CONFIRM_ICON ]

class ButtonComponent extends React.Component {
  static propTypes = {
    disabled: bool,
    dispatch: func.isRequired,
    fullWidth: bool,
    id: string,
    isLoading: bool,
    onClick: func,
    setRef: func,
    target: string,
    text: string.isRequired,
    to: string,
    type: oneOf( VALID_TYPES ).isRequired
  }

  static defaultProps = {
    disabled: false,
    fullWidth: false,
    grow: false,
    id: '',
    image: undefined,
    isLoading: false,
    onClick: undefined,
    setRef: undefined,
    target: '',
    to: '',
    type: ''
  }

  constructor( props ) {
    super( props )

    this.state = {
      loader: undefined,
      buttonModifer: this.initializeModifer( props )
    }
  }

  componentWillReceiveProps( nextProps ) {
    this.updateModifer( nextProps )
  }

  comopentWillMount() {
    this.updateModifer( this.props )
  }

  updateModifer( nextProps ) {
    let buttonModifer = this.initializeModifer( nextProps )

    this.setState( {
      loader: nextProps.isLoading ? ( <div className={ `button__loader` } /> ) : undefined,
      buttonModifer
    } )
  }

  initializeModifer( props ) {
    let buttonModifer = props.isLoading ? 'is-loading' : ''
    buttonModifer = buttonModifer === '' && props.disabled ? 'is-disabled' : buttonModifer
    return buttonModifer
  }

  getImage() {
    let hasImg = ICON_TYPES.includes( this.props.type ) || ICON_SETA_TYPES.includes( this.props.type )
    let icon = ICON_SETA_TYPES.includes( this.props.type ) ? 'chevron_right' : this.props.type
    let img = (
      <i
        className={ `button__img icon-${icon}` }
        src={ `` }
      />
    )
    return hasImg ? img : undefined
  }

  click() {
    if ( this.props.isLoading || this.props.disabled || !this.props.onClick ) {
      return
    } else {
      this.props.onClick()
    }
  }

  getContent() {
    const { isLoading, text } = this.props

    if ( !isLoading ) {
      return (
        <div className={ `button__content` }>
          <p className={ `button__text` }>{ text }</p>
          { this.getImage() }
        </div>
      )
    }
  }

  getButtonView() {
    const { dispatch, fullWidth, id, setRef, text, type } = this.props
    const { buttonModifer, loader } = this.state
    const classTypeButton = `button--${VALID_TYPES.includes( type ) ? type : TYPE_NORMAL}`
    const className = `${classTypeButton}${buttonModifer ? '--' + buttonModifer : ''}`

    return (
      <button
        className={ `button ${className} ${fullWidth && `button--full-width`}` }
        id={ id }
        onClick={ onClickButton( { dispatch, buttonName: text, fn: this.click.bind( this ) } ) }
        ref={ setRef }
      >
        { loader }
        { this.getContent() }
      </button>
    )
  }

  render() {
    const { dispatch, text } = this.props
    if ( this.props.to.length > 0 && !this.props.onClick ) {
      return (
        <Link
          className={ `button__link` }
          onClick={ onClickButton( { dispatch, buttonName: text } ) }
          target={ this.props.target }
          to={ this.props.to }
        >
          { this.getButtonView() }
        </Link>
      )
    } else {
      return this.getButtonView()
    }
  }
}

const Button = connect()( ButtonComponent )

export default Button
