import React, { Component } from 'react'
import { connect } from 'react-redux'
import { func, object } from 'prop-types'
import { Input, StyleGuide } from 'oef_componentes'
import styled from 'styled-components'

import { updatePessoa, validarCPFResgate, validarEmailResgate } from './actions'
import { getArrayDeErros } from '../../common/utils/modelUtils'
import { empty } from '../../shared/utils/validators'

const actions = {
  updatePessoa,
  validarCPFResgate,
  validarEmailResgate,
}

const WrapperPresenteadoResgateForm = styled.div`
  .row--double {
    @media (min-width: ${StyleGuide.mediaQuery.mobile})  {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }
  }

  .row--form {
    @media (min-width: ${StyleGuide.mediaQuery.mobile}) {
      margin-bottom: 20px;
    }
  }
`

class PresenteadoResgateForm extends Component {
  static propTypes = {
    presenteado: object.isRequired,
    updatePessoa: func.isRequired,
    validarCPFResgate: func.isRequired,
    validarEmailResgate: func.isRequired,
  }

  onBlurEmail = event => {
    const { value: email } = event.target
    if ( !empty( email ) ) {
      this.props.validarEmailResgate()
    }
    this.onBlurPresenteado( event )
  }

  onBlurCPF = event => {
    const { value: cpf } = event.target
    if ( !empty( cpf ) ) {
      this.props.validarCPFResgate()
    }
    this.onBlurPresenteado( event )
  }

  onBlurPresenteado = event => {
    const { name: campo } = event.target
    const erro = this.props.presenteado.validarCampo( campo )
    const erros = this.props.presenteado.erros.length > 0 ?
      getArrayDeErros( this.props.presenteado.erros, erro ) :
      [ erro ]
    this.props.updatePessoa( { ...this.props.presenteado.getRef(), erros } )
  }

  onChangePresenteado = event => {
    const { name: campo, value } = event.target
    const erro = { campo, mensagem: `` }
    const erros = getArrayDeErros( this.props.presenteado.erros, erro )
    this.props.updatePessoa( { ...this.props.presenteado.getRef(), erros, [ campo ]: value } )
  }

  render() {
    const { presenteado } = this.props
    return (
      <WrapperPresenteadoResgateForm>
        <div className={ `row--form` }>
          <Input
            erro={ presenteado.getErro( 'nome' ) }
            label={ `Nome completo` }
            name={ `nome` }
            onBlur={ this.onBlurPresenteado.bind( this ) }
            onChange={ this.onChangePresenteado.bind( this ) }
            value={ presenteado.nome || '' }
          />
        </div>
        <div className={ `row--form` }>
          <Input
            erro={ presenteado.getErro( 'email' ) }
            label={ `E-mail` }
            name={ `email` }
            onBlur={ this.onBlurEmail.bind( this ) }
            onChange={ this.onChangePresenteado.bind( this ) }
            type={ `email` }
            value={ presenteado.email || '' }
          />
        </div>
        <div className={ `row--form row--double` }>
          <Input
            column={ 2.1 }
            erro={ presenteado.getErro( 'cpf' ) }
            label={ `CPF` }
            maxLength={ 14 }
            name={ `cpf` }
            onBlur={ this.onBlurCPF.bind( this ) }
            onChange={ this.onChangePresenteado.bind( this ) }
            type={ Input.TIPO_TEL }
            value={ presenteado.cpf || '' }
          />
          <Input
            column={ 2.1 }
            erro={ presenteado.getErro( 'telefone' ) }
            label={ `Celular` }
            maxLength={ 15 }
            name={ `telefone` }
            onBlur={ this.onBlurPresenteado.bind( this ) }
            onChange={ this.onChangePresenteado.bind( this ) }
            tipo={ Input.TIPO_TEL }
            value={ presenteado.telefone || '' }
          />
        </div>
      </WrapperPresenteadoResgateForm>
    )
  }
}

export default connect(
  null,
  actions
)( PresenteadoResgateForm )
