import { createConditionalSliceReducer } from '../../common/utils/reducerUtils'
import orm from '../../schema/schema'
import DateHelper from '../../shared/helpers/DateHelper'

const updateAssinaturaPresente = ( state, payload ) => {
  const sessao = orm.session( state )
  const { AssinaturaPresente } = sessao

  const assinaturaPresente = AssinaturaPresente.all().first()

  if ( assinaturaPresente ) {
    const categoria = payload.categoria ? payload.categoria : assinaturaPresente.categoria
    let plano
    if ( payload.plano ) {
      plano = payload.plano
    } else {
      plano = payload.periodo ?
        categoria.planoSet.toModelArray()
          .find( plano => plano.totalKits === payload.periodo ) :
        assinaturaPresente.plano
    }

    assinaturaPresente.update( { ...payload, categoria, plano } )
  }

  return sessao.state
}

const receberAssinaturaPresente = ( state, { venda } ) => {
  const sessao = orm.session( state )
  const { AssinaturaPresente } = sessao
  const assinaturaPresente = AssinaturaPresente.all().first()

  const vendas = [ { id: venda.id } ]
  const { pagamento, url_pdf: pdf } = venda
  const { cobrancas, forma_pagamento: formaPagamento } = pagamento
  let boleto
  if ( formaPagamento === AssinaturaPresente.BOLETO.codigo ) {
    const cobranca = cobrancas[ 0 ]
    const { boleto: dadosBoleto } = cobranca
    boleto = {
      url: dadosBoleto.url,
      vencimento: DateHelper.serverWithTimeZoneToClient( dadosBoleto.vencimento ),
      codigoDeBarras: dadosBoleto.codigo_barras,
      valido: dadosBoleto.valido,
      valorTotal: dadosBoleto.valor_total,
    }
  }

  assinaturaPresente.update( { pdf, boleto, vendas } )

  return sessao.state
}

export default createConditionalSliceReducer( 'entities', {
  COMPLETAR_PASSO_CAIXINHA: updateAssinaturaPresente,
  UPDATE_ASSINATURA_PRESENTE: updateAssinaturaPresente,
  COMPLETAR_COMPRA_ASSINATURA_PRESENTE: receberAssinaturaPresente,
} )
