import { combineReducers } from 'redux'

import { reduceReducers } from '../common/utils/reducerUtils'

import entitiesReducer from '../funcionalidades/entidades/reducer'
import assinaturaPresenteReducer from '../funcionalidades/assinatura-presente/reducer'
import cartaoCreditoReducer from '../funcionalidades/cartao-credito/reducer'
import enderecoReducer from '../funcionalidades/endereco/reducer'
import etiquetaReducer from '../funcionalidades/etiqueta/reducer'
import pessoaReducer from '../funcionalidades/pessoa/reducer'
import fluxoReducer from '../funcionalidades/fluxo/reducer'
import apiReducer from './api'

const combinedReducer = combineReducers( {
  api: apiReducer,
  entities: entitiesReducer,
  fluxo: fluxoReducer,
} )

const rootReducer = reduceReducers(
  combinedReducer,
  assinaturaPresenteReducer,
  cartaoCreditoReducer,
  enderecoReducer,
  etiquetaReducer,
  pessoaReducer,
)

export default rootReducer
