import Fetch from '../utils/fetch'

const { REACT_APP_API_BASE_URL } = process.env

const template = logTemplate => values => ( { [ new Date().toISOString() ]: { ...logTemplate( values ), userAgent: navigator.userAgent } } )

export const actionCreator = ( { action={}, logTemplate=undefined } ) => {
  return {
    type: 'LEAD_LOG',
    ...action,
    meta: {
      leadLog: {
        logTemplate: template( logTemplate ),
      }
    }
  }
}

const getPasso = ( { screen } ) => {
  if ( String.prototype.endsWith ) {
    if ( screen.endsWith( `/` ) ) {
      screen = screen.slice( 0, -1 )
    }
  }
  return screen.split( `/` ).slice( -1 )[ 0 ]
}

const onFocusActionCreator = ( { action, fieldName } ) => {
  const logTemplate = ( { screen } ) => ( { passo: screen, tipo: 'selecionarCampo', message: `Usuário selecionou o campo ${fieldName} na tela ${screen}.` } )
  return actionCreator( { action, logTemplate } )
}

const onBlurActionCreator = ( { action, fieldName } ) => {
  const logTemplate = ( { screen } ) => ( { passo: screen, tipo: 'sairCampo', message: `Usuário saiu do campo ${fieldName} na tela ${screen}.` } )
  return actionCreator( { action, logTemplate } )
}

export const onSelectOptionActionCreator = ( { action, fieldName } ) => {
  const logTemplate = ( { screen } ) => ( { passo: screen, tipo: 'selecionarOpcao', message: `Usuário escolheu uma opção no campo ${fieldName} na tela ${screen}.` } )
  return actionCreator( { action, logTemplate } )
}

const onClickButtonActionCreator = ( { action, buttonName } ) => {
  const logTemplate = ( { screen } ) => ( { passo: screen, tipo: 'clicarBotao', message: `Usuário clicou no botão ${buttonName} na tela ${screen}.` } )
  return actionCreator( { action, logTemplate } )
}

export const onClickLinkActionCreator = ( { action, linkName } ) => {
  const logTemplate = ( { screen } ) => ( { passo: screen, tipo: 'clicarLink', message: `Usuário clicou no link ${linkName} na tela ${screen}` } )
  return actionCreator( { action, logTemplate } )
}

export const onDisplayErrorMessage = ( { action, message } ) => {
  const logTemplate = ( { screen } ) => ( { passo: screen, tipo: 'mensagemErro', message: `A mensagem "${message}" foi exibida para o usuário na tela ${screen}.` } )
  return actionCreator( { action, logTemplate } )
}

export const onFailChangeStep = ( { action, current, next } ) => {
  const logTemplate = () => ( { passo: current, tipo: 'falhaTrocaPasso', message: `Usuário tentou ir do passo ${current} para o passo ${next} mas a ação falhou.` } )
  return actionCreator( { action, logTemplate } )
}

export const onChangeStep = ( { action, former, current } ) => {
  former = getPasso( { screen: former } )
  current = getPasso( { screen: current } )
  const logTemplate = () => ( { passo: current, tipo: 'trocaPasso', message: `Usuário foi do passo ${former} para o passo ${current}` } )
  return actionCreator( { action, logTemplate } )
}

const onEvent = dispatchOnEvent => data => {
  const { dispatch, fn, ...values } = data
  return event => {
    dispatch( dispatchOnEvent( values ) )
    if ( fn ) {
      fn( event )
    }
  }
}

export const onBlur = onEvent( onBlurActionCreator )
export const onFocus = onEvent( onFocusActionCreator )
export const onClickButton = onEvent( onClickButtonActionCreator )
export const onSelectOption = onEvent( onSelectOptionActionCreator )
export const onClickLink = onEvent( onClickLinkActionCreator )

const middleware = store => next => action => {
  const state = store.getState()
  const lead = state.assinatura.associado.email ? { email: state.assinatura.associado.email } : undefined
  const screen = getPasso( { screen: window.location.pathname } )
  if ( action.meta && action.meta.leadLog && lead ) {
    if ( window.location.pathname.includes( `associe-se` ) ) {
      const fetch = new Fetch( {} )
      const url = `${REACT_APP_API_BASE_URL}/v2/lead-log`
      const { logTemplate } = action.meta.leadLog
      const body = { ...action.meta.leadLog, lead, log: logTemplate( { screen } ) }
      /**
       * body will follow the pattern:
       * {
       *   <datetime>: {
       *     <action>: <relevant data or message>
       *   }
       * }
       */
      fetch.post( { url, body } )
    }

    const { ...meta } = action.meta
    const newAction = { ...action, meta }
    return next( newAction )
  }

  return next( action )
}
export default middleware
