import React from 'react'
import { bool, object } from 'prop-types'

const Logo = ( { categoria, branco } ) => {
  if ( branco ) {
    if ( categoria.id === 1 ) {
      return (
        <i className={ `icon-curadoria-branco` } />
      )
    } else {
      return (
        <i className={ `icon-ineditos-branco` } />
      )
    }
  } else {
    if ( categoria.id === 1 ) {
      return (
        <span className="icon-curadoria">
          <i className="path1" />
          <i className="path2" />
          <i className="path3" />
          <i className="path4" />
          <i className="path5" />
        </span>
      )
    } else {
      return (
        <span className="icon-ineditos">
          <i className="path1" />
          <i className="path2" />
          <i className="path3" />
          <i className="path4" />
        </span>
      )
    }
  }
}

Logo.propTypes = {
  branco: bool,
  categoria: object.isRequired,
}

Logo.defaultProps = {
  branco: false,
}

export default Logo
