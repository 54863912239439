import { Storage } from './storage'
import { URL_BASE_LOGIN, URL_BASE_AREA_DO_ASSOCIADO, URL_BASE_ASSOCIE_SE } from '../constants'

const isAt = path => window.location.pathname.includes( path )

export const isAuthenticated = () => new Promise( ( resolve, reject ) => Storage.isAuthenticated() ? resolve() : reject() )
export const redirectIfNotAuthorized = () => isAuthenticated()
  .then( () => {
    if ( ( isAt( URL_BASE_ASSOCIE_SE ) || isAt( URL_BASE_LOGIN ) ) && !isAt( URL_BASE_AREA_DO_ASSOCIADO ) ) {
      window.location.pathname = URL_BASE_AREA_DO_ASSOCIADO
    }
  } )
  .catch( () => {
    if ( ( !isAt( URL_BASE_ASSOCIE_SE ) || isAt( !URL_BASE_LOGIN ) ) && isAt( URL_BASE_AREA_DO_ASSOCIADO ) ) {
      window.location.href = `${URL_BASE_LOGIN}?target=${window.location.pathname}`
    }
  } )

export const redirectIfAuthorized = url => isAuthenticated()
  .then( () => {
    window.location.href = url
  } )
