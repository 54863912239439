/*************************************************/
/*                  REACT LIBS                  */
/***********************************************/
import React from 'react'
import { bool } from 'prop-types'

export default class Loading extends React.Component {

  static propTypes = {
    fullHeight: bool,
  }

  static defaultProps = {
    fullHeight: false,
  }

  constructor( props ) {
    super( props )

    this.state = {
      loadingText: `carregando`
    }

    this.interval = setInterval( () => {
      let loadingText = ( this.state.loadingText.match( /\./g ) || [] ).length === 3 ? `carregando` : `${this.state.loadingText}.`
      this.setState( { loadingText } )
    }, 500 )
  }

  componentWillUnmount() {
    clearInterval( this.interval )
  }

  render() {
    return (
      <div className={ `loading${this.props.fullHeight ? '--full' : ''}` }>
        <div className="book">
          <div className="book__page">
            <div className="book__page__fill" />
            <div className="book__page__fill" />
            <div className="book__page__fill" />
            <div className="book__page__fill" />
            <div className="book__page__fill" />
          </div>
        </div>
        <h1 className={ `loading__text` }>{ this.state.loadingText }</h1>
      </div>
    )
  }
}
