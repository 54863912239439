import { createReducer } from '../../common/utils/reducerUtils'

import orm from '../../schema/schema'

const initialState = orm.getEmptyState()

const inicializar = ( state, payload ) => {
  const sessao = orm.session( state )
  const {
    AssinaturaPresente,
    CartaoCredito,
    Categoria,
    Endereco,
    Etiqueta,
    Pessoa,
    Plano
  } = sessao

  const models = [
    AssinaturaPresente,
    CartaoCredito,
    Categoria,
    Endereco,
    Etiqueta,
    Pessoa,
    Plano,
  ]

  models.forEach( modelType => {
    modelType.all().toModelArray().forEach( model => model.delete() )
  } )

  const { id: idEtiqueta } = Etiqueta.parse( {} )
  const { id: idEndereco } = Endereco.parse( {} )
  const { id: idAssinaturaPresente } = AssinaturaPresente.parse( {
    formaPagamento: AssinaturaPresente.CARTAO_CREDITO,
    tipo: AssinaturaPresente.CARTAO_VIRTUAL,
    parcelas: 1,
    etiqueta: idEtiqueta,
    endereco: idEndereco,
  } )
  Pessoa.parse( { assinatura: idAssinaturaPresente, tipo: Pessoa.PRESENTEADO } )
  Pessoa.parse( { assinatura: idAssinaturaPresente, tipo: Pessoa.PRESENTEADOR } )
  Pessoa.parse( { assinatura: idAssinaturaPresente, tipo: Pessoa.PRESENTEADO_RESGATE, genero: Pessoa.MASCULINO } )
  CartaoCredito.parse( {} )

  return sessao.state
}

const carregarCategorias = ( state, payload ) => {
  const sessao = orm.session( state )
  const {
    AssinaturaPresente,
    Categoria,
    Plano,
  } = sessao

  const {
    categorias=[],
  } = payload

  categorias.forEach( ( { planos, ...categoria } ) => {
    Categoria.parse( categoria )
    planos.forEach( plano => Plano.parse( { ...plano, categoria: categoria.id } ) )
  } )

  const categoria = Categoria.get( { nome: 'Curadoria' } )
  const plano = Plano.get( { categoria: categoria.id, totalKits: 1 } )
  const assinaturaPresente = AssinaturaPresente.all().first().ref
  AssinaturaPresente.parse( { ...assinaturaPresente, categoria, plano } )

  return sessao.state
}

export default createReducer( initialState, {
  INIT: inicializar,
  CARREGAR_CATEGORIAS: carregarCategorias
} )
