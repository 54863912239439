import React from 'react'
import { IndexRoute, Route } from 'react-router'

import asyncComponent from '../shared/components/asyncComponent'
import Loader from '../common/componentes/Loader'

import Resgate from '../layout/Resgate'

const Concluido = asyncComponent( () => import( '../layout/ResgateConcluido' ).then( m => m.default ), Loader )

const ResgateRoutes = () => {
  return (
    <Route
      path={ `/` }
    >
      <IndexRoute
        components={{ content: Resgate }}
      />
      <Route
        components={{ content: Concluido }}
        path={ `concluido` }
      />
    </Route>
  )
}

export default ResgateRoutes
