import { consultaCep } from '../../shared/utils/cep'
import { getSessaoEntidades } from '../entidades/selectors';

export function updateEndereco( payload ) {
  return {
    type: `UPDATE_ENDERECO`,
    payload
  }
}

export function getEnderecoPorCEP( payload ) {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Endereco } = sessao

    const enderecoExistente = Endereco.all().first()
    const assinaturaPresente = AssinaturaPresente.all().first()
    const { categoria } = assinaturaPresente

    const { cep } = payload

    const { frete, ...endereco } = await consultaCep( { cep, categoria: categoria.id } )

    dispatch( updateEndereco( { ...enderecoExistente.toRef(), ...endereco } ) )
    dispatch( {
      type: `RECEIVE_FRETE`,
      payload: frete
    } )
  }
}
