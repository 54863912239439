import { createConditionalSliceReducer } from '../../common/utils/reducerUtils'
import orm from '../../schema/schema'

const updateEndereco = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Endereco } = sessao

  Endereco.parse( payload )

  return sessao.state
}

const completarPassoEntrega = ( state, payload ) => {
  const sessao = orm.session( state )
  const { Endereco } = sessao

  const { endereco } = payload

  const enderecoExistente = Endereco.all().first()
  const idOEF = endereco.id

  Endereco.parse( { idOEF, ...enderecoExistente.toRef() } )

  return sessao.state
}

export default createConditionalSliceReducer( 'entities', {
  UPDATE_ENDERECO: updateEndereco,
  COMPLETAR_PASSO_ENTREGA: completarPassoEntrega,
} )
