import { createSelector } from 'reselect'

import orm from '../../schema/schema'

export const selecionarEntidades = state => state.entities

export const getSessaoEntidades = createSelector(
  selecionarEntidades,
  entidades => orm.session( entidades )
)
