import API from '../../data/API'
import { Storage } from '../../shared/utils/storage'
import { getSessaoEntidades } from '../entidades/selectors'

import { completarResgate } from '../fluxo/actions'

export function updateAssinaturaPresente( payload ) {
  return ( dispatch ) => {
    dispatch( {
      type: `UPDATE_ASSINATURA_PRESENTE`,
      payload
    } )
    dispatch( precisaValidarCodigo() )
  }
}

export const precisaValidarCodigo = () => {
  return {
    type: `PRECISA_VALIDAR`
  }
}

export const validandoCodigo = () => {
  return {
    type: `VALIDANDO_CODIGO`
  }
}

export const receberCodigo = () => {
  return {
    type: `RECEBER_CODIGO`
  }
}

export const receberCodigoInvalido = () => {
  return {
    type: `RECEBER_CODIGO_INVALIDO`
  }
}

export function validarCodigo( payload ) {
  return async function( dispatch, getState ) {
    dispatch( validandoCodigo() )
    const sessao = getSessaoEntidades( getState() )
    const { Categoria } = sessao

    let codigo
    let presente
    try {
      const resposta = await API.validarCodigo( payload )
      codigo = resposta.codigo
      presente = resposta.presente
    } catch ( resposta ) {
      const { errors } = await resposta
      let erros = {}
      for ( let erro of errors ) {
        erros[ erro.source ] = erro.message
      }
      dispatch( updateAssinaturaPresente( { erros } ) )
      dispatch( receberCodigoInvalido() )
      return
    }

    const categoria = Categoria.get( { nome: presente.categoria } )
    const plano = categoria.planoSet.toModelArray().find( plano => plano.id === presente.plano.id )

    dispatch( updateAssinaturaPresente( { codigo, categoria, plano } ) )
    dispatch( receberCodigo() )
  }
}

export function resgatarPresente() {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Endereco, Pessoa } = sessao

    const { codigo } = AssinaturaPresente.all().first()
    const endereco = Endereco.all().first()
    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO_RESGATE } )

    const payload = {
      codigo,
      entrega: {
        endereco: endereco.toJSON(),
      },
      presenteado: presenteado.toJSON(),
    }

    let token
    try {
      const response = await API.resgatar( payload )
      token = response.token
    } catch ( e ) {
      const erros = await e
      console.log( erros )
      return
    }
    Storage.setToken( token )
    Storage.setNome( presenteado.nome )
    dispatch( completarResgate() )
  }
}
