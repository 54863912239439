export class Storage {
  static isAuthenticated() {
    return !!Storage.getToken()
  }
  static clear() {
    this.clearToken()
    this.clearStore()
  }
  static getToken() {
    return localStorage.getItem( 'TAG_TOKEN' )
  }
  static setToken( token ) {
    localStorage.setItem( 'TAG_TOKEN', token )
  }
  static setNome( nome ) {
    if ( nome ) {
      localStorage.setItem( 'TAG_ASSOCIADO_NOME', nome.split( ' ' )[ 0 ] )
    }
  }
  static clearToken() {
    localStorage.removeItem( 'TAG_TOKEN' )
    localStorage.removeItem( 'TAG_ASSOCIADO_NOME' )
  }
  static getStore() {
    return JSON.parse( localStorage.getItem( 'REDUX_STORE' ) )
  }
  static setStore( store ) {
    localStorage.setItem( 'REDUX_STORE', JSON.stringify( store ) )
  }
  static clearStore() {
    localStorage.removeItem( 'REDUX_STORE' )
  }
  static getSessionItem( item ) {
    return sessionStorage.getItem( item )
  }
  static setCampo( { campo, valor } ) {
    localStorage.setItem( campo, valor )
  }
  static getCampo( { campo } ) {
    return localStorage.getItem( campo )
  }
}
