import API from '../../data/API'
import { Storage } from '../../shared/utils/storage'

import { getSessaoEntidades } from '../entidades/selectors'
import { URL_BASE_AREA_DO_ASSOCIADO } from '../../shared/constants'

const REACT_APP_CANONICAL_URL = process.env.REACT_APP_CANONICAL_URL
export function updatePessoa( payload ) {
  return {
    type: `UPDATE_PESSOA`,
    payload
  }
}

export const receiveErrosPresenteador = payload => {
  return {
    type: `RECEIVE_ERROS_PRESENTEADOR`,
    payload
  }
}

export const receiveErrosPresenteadoResgate = payload => {
  return {
    type: `RECEIVE_ERROS_PRESENTEADO_RESGATE`,
    payload
  }
}

export const validarEmailCompra = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { Pessoa } = sessao

    const presenteador = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )

    let payload
    try {
      payload = await API.validarLead( {
        presenteador: presenteador.toJSON()
      } )
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosPresenteador( erros ) )
      return
    }
    dispatch( {
      type: `UPDATE_PESSOA`,
      payload
    } )
  }
}

export const validarCPFCompra = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { Pessoa } = sessao

    const presenteador = Pessoa.get( { tipo: Pessoa.PRESENTEADOR } )

    let payload
    try {
      payload = await API.validarLead( {
        presenteador: presenteador.toJSON()
      } )
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosPresenteador( erros ) )
      return
    }
    dispatch( updatePessoa( payload ) )
  }
}

export const validarEmailResgate = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Pessoa } = sessao

    const { codigo } = AssinaturaPresente.all().first()
    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO_RESGATE } )

    let payload
    try {
      payload = await API.validarEmailResgate( {
        codigo,
        presenteado: presenteado.toJSON()
      } )
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosPresenteadoResgate( erros ) )
      return
    }
    dispatch( updatePessoa( { ...presenteado.getRef(), idOEF: payload.presenteado.id } ) )
  }
}

export const validarCPFResgate = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Pessoa } = sessao

    const { codigo } = AssinaturaPresente.all().first()
    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO_RESGATE } )

    let payload
    try {
      payload = await API.validarCPFResgate( {
        codigo,
        presenteado: presenteado.toJSON()
      } )
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosPresenteadoResgate( erros ) )
      return
    }
    dispatch( updatePessoa( { ...presenteado.getRef(), idOEF: payload.presenteado.id } ) )
  }
}

export const completarCadastroPresenteado = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { Pessoa } = sessao

    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO_RESGATE } )

    let token
    try {
      const response = await API.completarCadastroPresenteado( { associado: { ...presenteado.toJSON() } } )
      token = response.token
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosPresenteadoResgate( erros ) )
      return
    }
    Storage.setToken( token )
    window.location.href = `${REACT_APP_CANONICAL_URL}/${URL_BASE_AREA_DO_ASSOCIADO}`
  }
}

export const enviarEmailPDF = () => {
  return async function( dispatch, getState ) {
    const sessao = getSessaoEntidades( getState() )
    const { AssinaturaPresente, Pessoa } = sessao

    const presenteado = Pessoa.get( { tipo: Pessoa.PRESENTEADO } )
    const { vendas } = AssinaturaPresente.all().first()

    const idVenda = vendas[ 0 ].id
    try {
      await API.enviarEmailPDF( { id_venda: idVenda, email: presenteado.email } )
      dispatch( { type: 'EMAIL_PDF_ENVIADO' } )
      dispatch( updatePessoa( { ...presenteado.getRef(), email: `` } ) )
    } catch ( e ) {
      const erros = await e
      dispatch( receiveErrosEmailPresenteadoCompra( erros ) )
    }
  }
}

export const receiveErrosEmailPresenteadoCompra = payload => {
  return {
    type: `RECEIVE_ERROS_EMAIL_PRESENTEADO_COMPRA`,
    payload
  }
}
