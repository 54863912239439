import { empty } from '../utils/validators'
import { formatNumberTwoDigits } from '../utils/formatters'
import Moment from 'moment'

export const swapDayAndYear = ( dateString, separator ) => {
  if ( !dateString.includes( separator ) ) {
    return dateString
  }
  let dateArray = dateString.split( separator )
  let first = dateArray[ 0 ]
  let second = dateArray[ 1 ]
  let third = dateArray[ 2 ]
  return `${third}${separator}${second}${separator}${first}`
}
export const changeDateSeparator = ( dateString, oldSeparator, newSeparator ) => {
  if ( !dateString.includes( oldSeparator ) ) {
    return dateString
  }
  const regex = new RegExp( oldSeparator, `g` )
  return dateString.replace( regex, newSeparator )
}
const DateHelper = {
  abrevDate: ( mes, ano ) => `${mes.substring( 0, 3 )}/${ano.slice( -2 )}`,
  serverToClient: ( date ) => {
    let orderedDate = swapDayAndYear( date, '-' )
    let formattedDate = changeDateSeparator( orderedDate, '-', '/' )
    return formattedDate
  },
  serverWithTimeZoneToClientFull: dateString => {
    if ( !dateString ) {
      return ''
    }
    let date = new Date( dateString )
    let day = date.getDate()
    let month = formatNumberTwoDigits( date.getMonth() + 1 )
    let year = date.getFullYear()
    let hours = formatNumberTwoDigits( date.getHours() )
    let minutes = formatNumberTwoDigits( date.getMinutes() )

    return `${day}/${month}/${year} ${hours}:${minutes}`
  },
  serverWithTimeZoneToClient: date => {
    if ( !date ) {
      return ''
    }
    let format = date.substring( 0, 10 )
    return DateHelper.serverToClient( format )
  },
  clientToServer: ( date ) => {
    if ( !date ) {
      return ''
    }
    let orderedDate = swapDayAndYear( date, '/' )
    let formattedDate = changeDateSeparator( orderedDate, '/', '-' )
    return formattedDate
  },
  fromLocaleString: ( dateString ) => {
    let orderedDate = swapDayAndYear( dateString, '/' )
    return new Date( orderedDate )
  },
  toLocaleString: date => {
    if ( !empty( date ) ) {
      let month = date.getMonth() + 1
      let day = date.getDate()
      return `${( day < 10 ? '0' : '' ) + day}/${( month < 10 ? '0' : '' ) + month}/${date.getFullYear()}`
    } else {
      return ''
    }
  },
  toServerString: date => {
    if ( !date ) {
      return ''
    }
    const formattedDate = date => date.toISOString().split( 'T' )[ 0 ]
    if ( date instanceof Date && !isNaN( date.getTime() ) ) {
      return formattedDate( date )
    } else if ( date.length === 10 ) {
      let d = DateHelper.fromLocaleString( date )
      return formattedDate( d )
    }
  },
  monthToString: month => {
    Moment.locale( 'pt-br' )
    return Moment().month( month - 1 ).format( 'MMMM' )
  }
}

export default DateHelper
